import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getContactsUrl } from "@/util/urls"
import { contactsStore } from "@/stores/contactsStore"
import { Contact } from "@/types/Contact"
import { Paginated } from "@/types/Paginated"

export const getAllContacts = async (): Promise<Contact[]> => {
  if (contactsStore.state.contacts === null) {
    const contacts = await makeAuthenticatedRequest<Paginated<Contact>>(getContactsUrl(), "GET")

    contactsStore.setContacts(contacts.items)
  }

  return contactsStore.state.contacts as Contact[]
}

export const sendContactMessage = async (
  contactId: string,
  phone: string,
  name: string,
  message: string
): Promise<any> =>
  makeAuthenticatedRequest(`${getContactsUrl()}/${contactId}/messages`, "POST", {
    message,
    phone,
    name,
  })
