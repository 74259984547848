












import Vue from "vue"

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    variety: {
      type: String,
      default: "success",
    },
  },
  methods: {
    handleInput() {
      this.handleClose()
    },
    handleClose() {
      this.$emit("close")
    },
  },
})
