import { render, staticRenderFns } from "./AttorneysInsured.vue?vue&type=template&id=8fef63d0&scoped=true&"
import script from "./AttorneysInsured.vue?vue&type=script&lang=ts&"
export * from "./AttorneysInsured.vue?vue&type=script&lang=ts&"
import style0 from "./AttorneysInsured.vue?vue&type=style&index=0&id=8fef63d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fef63d0",
  null
  
)

export default component.exports