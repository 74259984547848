export const clientStore = {
  state: {
    clientName: null as null | string,
    clientKey: null as null | string,
    clientStatus: null as null | string,
  },

  setClientInfo(clientName: string, clientKey: string, clientStatus: string) {
    this.state.clientName = clientName
    this.state.clientKey = clientKey
    this.state.clientStatus = clientStatus
  },

  setClientName(clientName: string) {
    this.state.clientName = clientName
  },
}
