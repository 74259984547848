



















































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from "vue"
import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { estatesTrustsPolicyApplication } from "@/constants/policyApplicationForms"
import RadioGroup from "../../RadioGroup.vue"
import TextInput from "../../TextInput.vue"
import YesNoRadioGroup from "../../YesNoRadioGroup.vue"
import Form from "../Forms/Form.vue"
import FormBody from "../Forms/FormBody.vue"
import FormContent from "../Forms/FormContent.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import FormDetail from "../Forms/FormDetail.vue"
import FormRow from "../Forms/FormRow.vue"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import ListedQuestion from "../Forms/ListedQuestion.vue"
import ApplicationLink from "./ApplicationLink.vue"
import DocUploader from "../Forms/DocUploader.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    YesNoRadioGroup,
    RadioGroup,
    FormDatePicker,
    ListedQuestion,
    FormContent,
    FormBody,
    FormDetail,
    ApplicationLink,
    DocUploader,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(true)],
  data: () => ({
    DOCUMENT_CATEGORY_KEY,
    estateTaxReturnsLast12moItems: [
      { key: "0", label: "0" },
      { key: "1-5", label: "1-5" },
      { key: "6-10", label: "6-10" },
      { key: "> 10", label: "> 10" },
    ],
    formToSubmit: estatesTrustsPolicyApplication,
    userSubmissionForm: {
      type: "estatesTrusts",
      description: "Policy Application - E&T Application",
      items: {
        firmname: null,
        policykey: null,
        attorneyNameOne: null,
        attorneyNameTwo: null,
        attorneyNameThree: null,
        attorneyNameFour: null,
        simpleWills: null,
        engagementLetterIndividualInEstateTaxPlanning: null,
        engagementLetterIndividualInEstateTaxPlanningExpln: null,
        engagementLetterPersonalRepresentatitveOrEstate: null,
        engagementLetterPersonalRepresentatitveOrEstateExp: null,
        disengagementLetters: null,
        estateTaxReturnYearNumberRange: null,
        estateTaxReturnThreeYearNumberRange: null,
        notifyNotResponsible: null,
        netWorthLessThan5MPercentage: null,
        netWorthBetween5MAnd10MPercentage: null,
        netWorthGreaterThan10MPercentage: null,
        administrationWorkLessThan5MPercentage: null,
        administrationWorkBetween5MAnd10MPercentage: null,
        administrationWorkGreaterThan10MPercentage: null,
        purchaseOrSaleOfSecurities: null,
        purchaseOrSaleOfRealEstate: null,
        purchaseOrSaleOfOtherInvestments: null,
        adviseToRetainQualifiedProfesional: null,
        adviseToRetainQualifiedProfesionalExplanation: null,
        signature: {
          authorizedrepsignature: null,
          authorizedrepsignaturedate: null,
        },
      },
    },
    userSubmissionDocuments: {
      attorneyDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    },
  }),
})
