<template>
  <div class="role-based-dashboard">
    <Header
      :is-lmnc-admin="isLMNCAdmin"
      :is-client-admin="isClientAdmin"
      :is-user="isUser"
      :current-user="currentUser"
    />
    <main>
      <div v-if="isLoading" class="loading-page-spinner d-flex justify-center align-center">
        <v-progress-circular indeterminate color="primary" :size="80"></v-progress-circular>
      </div>
      <div v-else>
        <transition name="route" mode="out-in">
          <router-view
            class="view"
            :is-lmnc-admin="isLMNCAdmin"
            :is-client-admin="isClientAdmin"
            :is-user="isUser"
            :current-user="currentUser"
          />
        </transition>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { getKeycloak } from "@/keycloak/keycloak"
import { UserRole } from "@/constants/userRoles"
import { fetchUserInformation } from "@/services/authServices"
import { currentUserStateStore } from "@/stores/currentUserStore"
import Footer from "./Footer.vue"
import Header from "./Header.vue"

export default {
  components: { Header, Footer },
  data: () => ({
    isLoading: true,
    isLMNCAdmin: false,
    isClientAdmin: false,
    isUser: false,
    currentUser: null,
  }),
  async mounted() {
    await this.checkIsLMNCAdmin()
  },
  methods: {
    async checkIsLMNCAdmin() {
      const currentUser = await fetchUserInformation()

      this.currentUser = currentUser

      currentUserStateStore.setCurrentUser(currentUser)

      if (this.currentUser.role) {
        if (this.currentUser.role === UserRole.LMNC_ADMIN) {
          this.isLMNCAdmin = true
        }

        if (this.currentUser.role === UserRole.CLIENT_ADMIN) {
          this.isClientAdmin = true
        }

        if (this.currentUser.role === UserRole.USER) {
          this.isUser = true
        }
      } else {
        await this.handleLogout()
      }

      this.isLoading = false
    },

    async handleLogout() {
      try {
        await getKeycloak().logout()
      } catch (error) {
        this.$emit("error", { text: "Unable to logout", variety: "error" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.role-based-dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  main {
    margin-top: 18.3rem;
    flex-grow: 1;
    padding: 8rem 8rem 7rem 8rem;
  }

  .view {
    max-width: 128rem;
    margin: 0 auto;
  }

  .loading-page-spinner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
