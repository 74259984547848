

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import Vue, { VueConstructor } from "vue"
import { securitiesApplication } from "@/constants/policyApplicationForms"
import { PolicyFormDocuments } from "@/types/PolicyFormTypes"
import CheckboxGroup from "../../CheckboxGroup.vue"
import TextArea from "../../TextArea.vue"
import TextInput from "../../TextInput.vue"
import YesNoRadioGroup from "../../YesNoRadioGroup.vue"
import DocUploader from "../Forms/DocUploader.vue"
import Form from "../Forms/Form.vue"
import FormBody from "../Forms/FormBody.vue"
import FormContent from "../Forms/FormContent.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import FormDetail from "../Forms/FormDetail.vue"
import FormRow from "../Forms/FormRow.vue"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import ListedQuestion from "../Forms/ListedQuestion.vue"

type SecuritiesDocuments = {
  [key: string]: string | PolicyFormDocuments[]
}

type expansionContainerReturnValue = {
  key: string
  type: string
  value: string | PolicyFormDocuments[]
}

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    ListedQuestion,
    YesNoRadioGroup,
    FormDatePicker,
    TextArea,
    CheckboxGroup,
    DocUploader,
    FormBody,
    FormDetail,
    FormContent,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(true)],
  data: () => ({
    DOCUMENT_CATEGORY_KEY,
    firmSecurityPracticesItems: [
      { key: 1, label: "Issuers?", value: "Issuers" },
      { key: 2, label: "Underwriter/Placement Agents?", value: "Underwriter/Placement Agents" },
      { key: 3, label: "Broker-Dealers?", value: "Broker-Dealers" },
      { key: 4, label: "Investment Advisers?", value: "Investment Advisers" },
      { key: 5, label: "Investors?", value: "Investors" },
      { key: 6, label: "None of these", value: "None of these", exclusive: true },
    ],
    formToSubmit: securitiesApplication,
    userSubmissionForm: {
      type: "securities",
      items: {
        policykey: null,
        firmname: null,
        attorneyNameOne: null,
        attorneyNameTwo: null,
        attorneyNameThree: null,
        attorneyYearsExperienceOne: null,
        attorneyYearsExperienceTwo: null,
        attorneyYearsExperienceThree: null,
        attorneyAgencyOne: null,
        attorneyAgencyTwo: null,
        attorneyAgencyThree: null,
        attorneyYearsOfServiceOne: null,
        attorneyYearsOfServiceTwo: null,
        attorneyYearsOfServiceThree: null,
        dollarsDerived: null,
        percentageSecuritiesWork: null,
        lawyerReprimanded: null,
        representIssures: null,
        representUnderwriters: null,
        representBrokerDealers: null,
        representInvestmentAdvisers: null,
        representInvestors: null,
        largestClientOne: null,
        largestClientOneTypeOfBusiness: null,
        largestClientTwoTypeOfBusiness: null,
        largestClientThreeTypeOfBusiness: null,
        largestClientFourTypeOfBusiness: null,
        largestClientFour: null,
        largestClientThree: null,
        largestClientTwo: null,
        federalSecuritiesRegistrationDateOne: null,
        federalSecuritiesRegistrationDateTwo: null,
        federalSecuritiesRegistrationDateThree: null,
        federalSecuritiesClientNameOne: null,
        federalSecuritiesClientNameTwo: null,
        federalSecuritiesClientNameThree: null,
        federalSecuritiesSizeOfferingOne: null,
        federalSecuritiesSizeOfferingTwo: null,
        federalSecuritiesSizeOfferingThree: null,
        stopOrder: null,
        stopOrderExplanation: null,
        entityTypes: [],
        negativeAssurance: null,
        finraDateOne: null,
        finraDateTwo: null,
        finraDateThree: null,
        finraClientNameOne: null,
        finraClientNameTwo: null,
        finraClientNameThree: null,
        finraSizeOne: null,
        finraSizeTwo: null,
        finraSizeThree: null,
        finraIssuerNameOne: null,
        finraIssuerNameTwo: null,
        finraIssuerNameThree: null,
        finra: null,
        blueSky: null,
        blueSkyExplanation: null,
        exemptOfferings: null,
        exemptOfferingsIssuerClientsNumberTransactions: null,
        exemptOfferingsIssuerClientsDollarAmount: null,
        exemptOfferingsUnderwriterNumberTransactions: null,
        exemptOfferingsUnderwriterDollarAmount: null,
        exemptOfferingsInvestorDollarAmount: null,
        exemptOfferingsInvestorNumberTransactions: null,
        obligationsProxyStatements: null,
        obligationsProxyStatementsExplanation: null,
        investmentCompanyAct: null,
        investmentCompanyActExplanation: null,
        outsideUS: null,
        outsideUSExplanation: null,
        assetBacked: null,
        assetBackedExplanation: null,
        pennyStocks: null,
        transferAgent: null,
        transferAgentPublicCompanies: null,
        escrowAgent: null,
        stateRegistration: null,
        signature: {
          signDate: null,
          signature: null,
        },
      },
    },
    userSubmissionDocuments: {
      lawyerreprimandedexplanation: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      pastyrfedsecuritiesinfo: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      secstopregistrationexplanation: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      representbeforefinrainfo: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      secattorneyDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    } as unknown as SecuritiesDocuments,
  }),
  methods: {
    handleExpansionValueInput(value: expansionContainerReturnValue) {
      if (value.type === "document") {
        this.userSubmissionDocuments[value.key] = value.value
      }
    },
  },
})
