










import Vue from "vue"
import SlideUpToggleTransition from "./SlideUpToggleTransition.vue"

export default Vue.extend({
  components: { SlideUpToggleTransition },
  props: {
    requirementLabel: {
      type: String,
      required: true,
    },
    ruleRegex: {
      type: String,
      required: true,
    },
    passwordValue: {
      type: String,
      required: true,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    regex(): RegExp {
      return new RegExp(this.ruleRegex)
    },
    valid(): boolean {
      return this.regex.test(this.passwordValue)
    },
  },
})
