
















































































































































































































































































































































































































































import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { mergersAcquisitionsApplication } from "@/constants/policyApplicationForms"
import Vue, { VueConstructor } from "vue"
import TextInput from "../../TextInput.vue"
import YesNoRadioGroup from "../../YesNoRadioGroup.vue"
import Form from "../Forms/Form.vue"
import FormBody from "../Forms/FormBody.vue"
import FormContent from "../Forms/FormContent.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import FormDetail from "../Forms/FormDetail.vue"
import FormRow from "../Forms/FormRow.vue"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import ListedQuestion from "../Forms/ListedQuestion.vue"
import DocUploader from "../Forms/DocUploader.vue"
import AttorneyInsuredSelect from "../../AttorneyInsuredSelect.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    YesNoRadioGroup,
    FormDatePicker,
    ListedQuestion,
    FormContent,
    FormBody,
    FormDetail,
    DocUploader,
    AttorneyInsuredSelect,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(true)],
  data: () => ({
    DOCUMENT_CATEGORY_KEY,
    formToSubmit: mergersAcquisitionsApplication,
    attorneyNamesList: "",
    userSubmissionForm: {
      type: "mergersAcquisitions",
      items: {
        firmname: null,
        policykey: null,
        mergersAcquisitionsAttorneyNames: null as null | string,
        renderedServicesToMergerOfPublicCompanies: null,
        renderedServicesToMergerOfPrivateCompanies: null,
        renderedServicesToAcquisitionOfPublicCompanies: null,
        renderedServicesToAcquisitionOfPrivateCompanies: null,
        largestMergerDollarAmountOne: null,
        largestMergerDollarAmountTwo: null,
        largestMergerDollarAmountThree: null,
        largestAcquisitionDollarAmountOne: null,
        largestAcquisitionDollarAmountTwo: null,
        largestAcquisitionDollarAmountThree: null,
        registrationOfSecurities: null,
        engagementLetter: null,
        equityInterest: null,
        representedAcquiringAndAcquired: null,
        representedAcquiringAndAcquiredDiscloseConflicts: null,
        representedBothPartiesMerger: null,
        representedBothPartiesMergerDisclosedConflicts: null,
        signature: {
          signDate: null,
          signature: null,
        },
      },
    },
    userSubmissionDocuments: {
      attorneyDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    },
  }),
  methods: {
    handleAttorneyListUpdated(value: string) {
      this.userSubmissionForm.items.mergersAcquisitionsAttorneyNames = value
    },
  },
})
