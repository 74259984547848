













import SlideUpToggleTransition from "@/components/SlideUpToggleTransition.vue"
import Vue from "vue"

export default Vue.extend({
  components: { SlideUpToggleTransition },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    spinnerSize: {
      type: Number,
      default: 50,
    },
    centered: {
      type: Boolean,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    loaderClass(): string {
      return `loader${this.centered ? " centered" : ""}`
    },
  },
})
