

































import { validateRequired } from "@/validators/validateRequired"
import Vue from "vue"

export default Vue.extend({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: null,
    },
    attach: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    selectedItem: null,
    validateRequired,
  }),
  computed: {
    loadedList(): unknown[] {
      return this.loading ? [] : this.items
    },
    loadedLabel(): string {
      return this.loading ? "" : this.label
    },
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
  },
})
