import Vue from "vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { Message } from "@/types/Message"
import { postClientMessage, postPolicyMessage } from "@/services/messageService"
import { policyFormSubmittedStore } from "@/stores/policyFormSubmittedStore"
import { UserRecordWithClient } from "@/types/UserRecord"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"

const POLICY_APPLICATION_TYPES = [
  "estatesTrusts",
  "intellectualProperty",
  "mergersAcquisitions",
  "plaintiffPersonalInjury",
  "realEstate",
  "securities",
]

export const PolicySubmissionMixin = Vue.extend({
  data: () => ({
    formName: "",
    showSubmissionDialog: false,
    messageDataToSubmit: null as any,
    submittingMessage: false,
    showSignatureErrorSnackbar: false,
    messageSubmissionErrorOccurred: false,
  }),
  methods: {
    getMessageWithoutEmptyDocuments(message: Message) {
      if (message.documents === null) {
        return message
      }

      const coalescedDocuments = message.documents.filter(document => document.base64Content !== null)

      return {
        ...message,
        documents: coalescedDocuments,
      }
    },
    async handleSubmit(messageData: any) {
      this.showSubmissionDialog = true
      this.messageDataToSubmit = messageData
      this.messageSubmissionErrorOccurred = false
    },
    handleSignatureError() {
      this.showSignatureErrorSnackbar = true
    },
    handleSignatureErrorSnackbarClosed() {
      this.showSignatureErrorSnackbar = false
    },
    sendPolicyOrClientMessage(user: UserRecordWithClient) {
      const message = this.getMessageWithoutEmptyDocuments(this.messageDataToSubmit)

      if (POLICY_APPLICATION_TYPES.includes(this.messageDataToSubmit.type)) {
        return postClientMessage(getClientKeyFromUserOrUrl(this, user), message)
      }

      return postPolicyMessage(getClientKeyFromUserOrUrl(this, user), this.$route.params.policyKey, message)
    },
    async sendMessage() {
      safelyDoWithUserInfo(async user => {
        this.submittingMessage = true
        this.messageSubmissionErrorOccurred = false

        try {
          await this.sendPolicyOrClientMessage(user)

          this.showSubmissionDialog = false
          policyFormSubmittedStore.setPolicyFormSubmitted(true)
          policyFormSubmittedStore.setPolicyFormName(this.formName)

          const { policyKey } = this.$route.params

          if (typeof policyKey === "undefined") {
            this.$router.push(await appendFirmsIfAdminViewingClient(`/policies`, getClientKeyFromUserOrUrl(this, user)))
          } else {
            this.$router.push(
              await appendFirmsIfAdminViewingClient(
                `/policies/${this.$route.params.policyKey}`,
                getClientKeyFromUserOrUrl(this, user)
              )
            )
          }
        } catch (error) {
          this.messageSubmissionErrorOccurred = true
          this.$emit("error", { text: `An error occurred sending a policy message`, variety: "success" })
        }

        this.submittingMessage = false
      })
    },
    handleSubmissionCancelled() {
      this.showSubmissionDialog = false
    },
  },
})
