







import Vue from "vue"
import Button from "./Button.vue"

export default Vue.extend({
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event: Event) {
      this.$emit("click", event)
    },
  },
})
