<template>
  <div v-if="keycloakToken">
    <!-- Ensure token is loaded before rendering -->
    <h1>Test</h1>
    <ReissueForm :policy-key="policyKey" :keycloak-token="keycloakToken" />
  </div>
  <div v-else>
    <!-- Optional: show a loading message or spinner until token is available -->
    <p>Loading...</p>
  </div>
</template>

<script>
import { getKeycloak } from "@/keycloak/keycloak"
import ReissueForm from "../Forms/ReissueForm.vue"

export default {
  name: "FormioTestComponent",
  components: {
    ReissueForm,
  },
  data() {
    return {
      policyKey: this.$route.params.policyKey,
      keycloakToken: null, // Initialize as null
    }
  },
  created() {
    this.loadToken() // Load the token in the created lifecycle hook
  },
  methods: {
    async loadToken() {
      try {
        this.keycloakToken = getKeycloak().token // Set the token once available
      } catch (error) {
        console.error("Failed to load Keycloak token:", error)
      }
    },
  },
}
</script>
