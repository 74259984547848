







import Vue from "vue"

export default Vue.extend({
  props: {
    label: {
      type: [String, Number],
      default: null,
    },
    question: {
      type: String,
      default: null,
    },
    pTop: {
      type: Boolean,
      default: null,
    },
    pBtm: {
      type: Boolean,
      default: null,
    },
    pLeft: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    addedPadding() {
      return `padding: ${this.pTop ? "1.5rem" : "0rem"} 0rem ${this.pBtm ? "1.5rem" : "0rem"} ${
        this.pLeft ? "1.5rem" : "0rem"
      };`
    },
  },
})
