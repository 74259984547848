



































































































































































































import Vue, { VueConstructor } from "vue"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { addressChangeForm } from "@/constants/policyChangeForms"
import { AutofillAddress } from "@/mixins/autoFillAddress"
import Form from "../Forms/Form.vue"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import FormRow from "../Forms/FormRow.vue"
import TextInput from "../../TextInput.vue"
import FormStateSelect from "../Forms/FormStateSelect.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import RadioGroup from "../../RadioGroup.vue"
import FormContent from "../Forms/FormContent.vue"
import FormBody from "../Forms/FormBody.vue"
import FormDetail from "../Forms/FormDetail.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    FormStateSelect,
    FormDatePicker,
    RadioGroup,
    FormContent,
    FormBody,
    FormDetail,
  },
  mixins: [
    FormSubmissionMixin,
    AutofillFirmInfoMixin(),
    AutofillAddress({
      addressPropertyName: "priorstreetorpobox",
      cityPropertyName: "priorcity",
      statePropertyName: "priorstate",
      zipPropertyName: "priorzip",
    }),
  ],
  data: () => ({
    addressTypes: [
      { key: "mailing", label: "Mailing" },
      { key: "physical", label: "Physical" },
      { key: "secondary", label: "Secondary" },
    ],
    formToSubmit: addressChangeForm,
    userSubmissionForm: {
      type: "addressChange",
      description: "Change of Address",
      items: {
        firmname: null,
        policykey: null,
        priorAddressType: null,
        priorstreetorpobox: null,
        priorcity: null,
        priorstate: null,
        priorzip: null,
        newaddress: null,
        newstreetorpobox: null,
        newcity: null,
        newstate: null,
        newzip: null,
        newAddressType: null,
        effectivedate: null,
        firmwebsite: null,
        businessnumber: null,
        faxnumber: null,
        signature: {
          partnersignature: null,
          partnersigndate: null,
        },
      },
    },
  }),
})
