





























import Vue, { VueConstructor } from "vue"
import { PolicySubmissionMixin } from "@/mixins/policySubmission"
import PolicySubmission from "@/components/Policies/PolicySubmission.vue"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import BillingLink from "../Billing/BillingLink.vue"
import PolicyContent from "./PolicyContent.vue"

type PolicyChangeLink = { label: string; to: string }

export default (Vue as VueConstructor<Vue & typeof PolicySubmissionMixin>).extend({
  components: { PolicyContent, BillingLink, PolicySubmission },
  mixins: [PolicySubmissionMixin],
  data() {
    return {
      formName: "Policy change request",
      items: [] as PolicyChangeLink[],
    }
  },
  async mounted() {
    this.items = [
      {
        label: "Add Attorney",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/add-attorney`
        ),
      },
      {
        label: "Remove Attorney",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/remove-attorney`
        ),
      },
      {
        label: "Change Address",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/change-address`
        ),
      },
      {
        label: "Change Business Name",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/change-business-name`
        ),
      },
      {
        label: "Policy Cancellation",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/policy-cancellation`
        ),
      },
      {
        label: "Request ERE (Tail Coverage) Quotes",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/request-ere-quote`
        ),
      },
      {
        label: "Request Mid-Term Limit Change",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/request-mid-change`
        ),
      },
      {
        label: "Attorney Name Change",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/attorney-name-change`
        ),
      },
      {
        label: "Form.io Renewal Test",
        to: await this.getAdminLinkIfOnAdminView(
          `/policies/${this.$route.params.policyKey}/policy-change/renewal-form`
        ),
      },
    ]
  },
  methods: {
    getAdminLinkIfOnAdminView(url: string) {
      return appendFirmsIfAdminViewingClient(url, this.$route.params.clientKey)
    },
  },
})
