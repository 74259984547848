<template>
  <div class="user-info-template-content">
    <div v-if="returnBtn !== null" class="user-info-template-back-btn-container">
      <v-btn plain color="primary" class="user-info-template-back-btn" @click="handleBackButton">
        <v-icon>mdi-chevron-left</v-icon>{{ returnBtn }}
      </v-btn>
    </div>
    <div class="user-info-template-title">
      <h1>{{ title }}</h1>
    </div>
    <div class="user-form-content">
      <Form
        class="user-info-form-container"
        :button-label="`${submitLabel}`"
        form-margin="0"
        min-width="45rem"
        button-variety="button-start"
        @submit="handleSubmit"
      >
        <FormSection :divider="false">
          <div v-if="isLoading" class="loading-page-spinner d-flex justify-center align-center">
            <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
          </div>
          <FormSectionContent v-else>
            <template #row-1>
              <FormRow v-if="!isUserProfile && !selectedUser">
                <template #column-1>
                  <FormContent p-top>
                    <TextInput
                      v-model="formData.email"
                      required
                      label="Email Address"
                      animate-label
                      :loading="checkingEmailInUse"
                      :verified="emailVerified"
                      :error-messages="emailError"
                      @blur="handleEmailBlurred"
                      @input="debouncedEmailSearch"
                    ></TextInput>
                  </FormContent>
                </template>
                <template #column-2>
                  <FormContent p-top>
                    <TextInput v-model="formData.phone" phone required label="Phone" animate-label></TextInput>
                  </FormContent>
                </template>
              </FormRow>
            </template>
            <template #row-2>
              <FormRow p-top>
                <template #column-1>
                  <FormContent>
                    <TextInput v-model="formData.firstName" required label="First Name" animate-label></TextInput>
                  </FormContent>
                </template>
                <template #column-2>
                  <FormContent>
                    <TextInput v-model="formData.lastName" required label="Last Name" animate-label></TextInput>
                  </FormContent>
                </template>
              </FormRow>
            </template>
            <template #row-3>
              <FormRow v-if="isUserProfile || selectedUser">
                <template #column-1>
                  <FormContent p-top>
                    <TextInput v-model="formData.phone" phone required label="Phone" animate-label></TextInput>
                  </FormContent>
                </template>
                <template #column-2>
                  <FormContent p-top>
                    <v-checkbox
                      v-if="isAdmin"
                      :key="formData.id"
                      label="Firm Administrator"
                      :input-value="formData.isClientAdmin"
                      on-icon="mdi-checkbox-marked"
                      @change="handleUpdateUserRole(formData.id, $event)"
                    />
                  </FormContent>
                </template>
              </FormRow>
              <FormRow v-if="!isUserProfile && !selectedUser">
                <template #column-1>
                  <FormContent p-btm>
                    <v-checkbox
                      :key="formData.id"
                      label="Firm Administrator"
                      :input-value="formData.isClientAdmin"
                      on-icon="mdi-checkbox-marked"
                      @change="handleUpdateUserRole(formData.id, $event)"
                    />
                  </FormContent>
                </template>
              </FormRow>
            </template>
          </FormSectionContent>
          <ConfirmPasswordForm
            v-if="isUserProfile && !isLoading"
            :value="userProfileFormData.password"
            :confirm-value="userProfileFormData.confirmPassword"
            is-one-row
            @input="handleInput"
            @confirm-input="handleConfirmInput"
          />
        </FormSection>
        <template #secondary-btn>
          <div class="cancel-btn-container">
            |
            <v-btn class="cancel-btn" plain color="primary" @click="handleBackButton"> Cancel</v-btn>
          </div>
        </template>
      </Form>
    </div>
  </div>
</template>

<script>
import { debounce } from "vue-debounce"
import { denormalizePhoneNumber } from "@/util/normalizePhoneNumber"
import { DEBOUNCE_INTERVAL } from "@/constants/debounceInternval"
import { fetchEmailInUse } from "@/services/authServices"
import { UserRole } from "@/constants/userRoles"
import { isAdmin } from "@/util/isAdmin"
import Form from "../Policies/Forms/Form.vue"
import FormContent from "../Policies/Forms/FormContent.vue"
import FormRow from "../Policies/Forms/FormRow.vue"
import FormSection from "../Policies/Forms/FormSection.vue"
import FormSectionContent from "../Policies/Forms/FormSectionContent.vue"
import TextInput from "../TextInput.vue"
import ConfirmPasswordForm from "../ConfirmPasswordForm.vue"

const EMAIL_REGEX = /.+@.+\..+/

export default {
  components: { FormSection, FormSectionContent, FormRow, FormContent, TextInput, Form, ConfirmPasswordForm },
  props: {
    title: {
      type: String,
      default: null,
    },
    returnBtn: {
      type: String,
      default: null,
    },
    submitLabel: {
      type: String,
      default: null,
    },
    selectedUser: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isUserProfile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formData: {
      email: null,
      isClientAdmin: false,
      phone: null,
      firstName: null,
      lastName: null,
    },
    checkingEmailInUse: false,
    emailInUse: null,
    emailBlurred: false,
    emailDirty: false,
    userProfileFormData: {
      password: null,
      confirmPassword: null,
    },
    errorOccurred: false,
    errorMessage: null,
    isAdmin: false,
  }),
  computed: {
    emailVerified() {
      return this.formData.email?.length > 0 && this.emailInUse === false
    },
    emailError() {
      if (this.emailDirty && this.emailInUse) {
        return ["This email address is already in-use"]
      }

      return this.emailBlurred && !this.emailVerified && !this.checkingEmailInUse
        ? ["Please enter a valid email address"]
        : null
    },
  },
  watch: {
    isLoading(currentValue) {
      if (currentValue === false) {
        this.initializeForm()
      }
    },
  },
  created() {
    this.debouncedEmailSearch = debounce(email => {
      this.checkEmailInUse(email)
    }, DEBOUNCE_INTERVAL)
  },
  mounted() {
    if (this.isLoading === false) {
      this.initializeForm()
    }

    this.isAdmin = this.selectedUser && isAdmin(this.selectedUser)
  },
  methods: {
    handleBackButton() {
      this.$emit("back")
    },

    handleSubmit() {
      if (this.isUserProfile) {
        this.submitFormForUserProfile()
      } else if (!this.isUserProfile && !this.selectedUser) {
        this.submitFormForAddingFirmUser()
      } else {
        this.submitFormForEditingFirmUser()
      }
    },

    submitFormForAddingFirmUser() {
      const { clientKey } = this.$route.params
      const updatedFormData = { ...this.formData, clientKey }

      if (!this.emailVerified) {
        this.errorMessage = "Please enter a valid email address"
      }

      if (!this.emailVerified || !this.requiredFormItemsCompleted(updatedFormData)) {
        this.$emit("error", { text: `${this.errorMessage}`, variety: "error" })
        this.errorOccurred = false
        this.errorMessage = null
      } else {
        this.$emit("submit", updatedFormData)
      }
    },

    submitFormForEditingFirmUser() {
      const { clientKey } = this.$route.params
      const updatedFormData = { ...this.formData, clientKey }
      delete updatedFormData.email
      if (!this.requiredFormItemsCompleted(updatedFormData)) {
        this.$emit("error", { text: `${this.errorMessage}`, variety: "error" })
        this.errorOccurred = false
        this.errorMessage = null
      } else {
        this.$emit("submit", updatedFormData)
      }
    },

    submitFormForUserProfile() {
      const { clientKey } = this.$route.params
      const updatedFormData = { ...this.formData, clientKey }
      delete updatedFormData.email
      if (this.requiredFormItemsCompleted(updatedFormData)) {
        if (this.userProfileFormData.password !== null && this.userProfileFormData.confirmPassword !== null) {
          if (this.isPasswordValid(this.userProfileFormData.password, this.userProfileFormData.confirmPassword)) {
            this.$emit("submit", { ...updatedFormData, password: this.userProfileFormData.password })
          } else {
            this.$emit("error", { text: `${this.errorMessage}`, variety: "error" })
            this.errorOccurred = false
            this.errorMessage = null
          }
        } else {
          this.$emit("submit", updatedFormData)
        }
      } else {
        this.$emit("error", { text: `${this.errorMessage}`, variety: "error" })
        this.errorOccurred = false
        this.errorMessage = null
      }
    },

    requiredFormItemsCompleted(formObject) {
      let response
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(formObject)) {
        if (value === null || value === "") {
          this.errorOccurred = true
          this.errorMessage = `Missing required forms for submission.`
          response = key
        }
      }
      if (response) {
        return false
      }
      return true
    },

    initializeForm() {
      if (this.selectedUser !== null) {
        this.formData.isClientAdmin = this.selectedUser.role === UserRole.CLIENT_ADMIN
        this.formData.phone = denormalizePhoneNumber(this.selectedUser.phone)
        this.formData.firstName = this.selectedUser.firstName
        this.formData.lastName = this.selectedUser.lastName
      }
    },

    handleInput(value) {
      this.userProfileFormData.password = value
    },
    handleConfirmInput(value) {
      this.userProfileFormData.confirmPassword = value
    },

    isPasswordValid(password, confirmPassword) {
      if (password === null || password === "" || password.length < 12) {
        this.errorOccurred = true
        this.errorMessage = `Password must be at least 12 characters.`
        return false
      }
      if (password !== confirmPassword) {
        this.errorOccurred = true
        this.errorMessage = `Password and Confirm Password must match.`
        return false
      }
      if (!this.checkForRequiredCharacters(password)) {
        this.errorOccurred = true
        this.errorMessage = `Password must contain an uppercase letter, a lowercase letter, a number, and a special character.`
        return false
      }
      return true
    },

    checkForRequiredCharacters(string) {
      const specialCharacterTest = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"/
      return specialCharacterTest.test(string)
    },

    handleUpdateUserRole(id, event) {
      this.formData.isClientAdmin = event
    },

    async checkEmailInUse(value) {
      this.emailDirty = true
      this.checkingEmailInUse = true
      this.emailInUse = null

      if (EMAIL_REGEX.test(value)) {
        const emailInUseResponse = await fetchEmailInUse(value)

        this.emailInUse = emailInUseResponse.inUse
      }

      this.checkingEmailInUse = false
    },
    handleEmailBlurred() {
      this.emailBlurred = true
    },
  },
}
</script>

<style lang="scss" scoped>
.user-info-template-content {
  height: 100%;
  width: 100%;

  .user-info-template-back-btn {
    text-transform: none;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 4.3rem;
    letter-spacing: 0.03rem;
    padding: 0 0 1rem 0;
  }

  .user-info-template-table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 2rem 0;
  }

  .loading-page-spinner {
    height: 25rem;
    width: 100%;
  }

  .user-form-content {
    display: flex;
    padding-top: 4rem;

    .user-info-form-container {
      width: 93rem;

      .cancel-btn-container {
        padding-left: 3rem;
        font-size: 1.4rem;

        .cancel-btn {
          margin-left: 1rem;
          text-transform: none;
          font-size: 1.4rem;
          line-height: 1.7rem;
          letter-spacing: 00.03rem;
        }
      }
    }
  }

  .client-name-label {
    padding-top: 0.5rem;
    font-size: 1.2rem;
  }

  .client-name-content {
    font-size: 1.6rem;
  }
}
</style>
