<template>
  <div class="confirmation-container">
    <FormSectionContent v-if="isOneRow">
      <template #row-1>
        <div class="change-password-label">
          <div class="change-password-header">Change Password</div>
        </div>
      </template>
      <template #row-2>
        <FormRow p-top>
          <template #column-1>
            <FormContent>
              <TextInput
                :value="value"
                type="password"
                label="Password"
                required
                animate-label
                @input="handleInput"
              ></TextInput>
            </FormContent>
          </template>
          <template #column-2>
            <FormContent>
              <TextInput
                :value="confirmValue"
                type="password"
                label="Confirm Password"
                required
                animate-label
                @input="handleConfirmInput"
              ></TextInput>
            </FormContent>
          </template>
        </FormRow>
      </template>
    </FormSectionContent>

    <FormSectionContent v-else>
      <template #row-1>
        <FormRow p-top>
          <template #column-1>
            <FormContent p-top p-btm p-left p-right>
              <TextInput
                :value="value"
                type="password"
                label="Password"
                required
                animate-label
                @input="handleInput"
              ></TextInput>
            </FormContent>
          </template>
        </FormRow>
      </template>
      <template #row-2>
        <FormRow>
          <template #column-1>
            <FormContent p-left p-right>
              <TextInput
                :value="confirmValue"
                type="password"
                label="Confirm Password"
                required
                animate-label
                @input="handleConfirmInput"
              ></TextInput>
            </FormContent>
          </template>
        </FormRow>
      </template>
      <template #row-3>
        <FormRow p-left>
          <template #column-1>
            <FormContent p-left p-btm>
              <div class="change-password-label">
                <div class="change-password-rules">
                  Password must be at least 12 characters and contain an uppercase letter, a lowercase letter, a number
                  and a special character.
                </div>
              </div>
            </FormContent>
          </template>
        </FormRow>
      </template>
    </FormSectionContent>
    <ChangePasswordRules :dirty="dirty" :value="value" />
  </div>
</template>

<script>
import ChangePasswordRules from "@/components/ChangePasswordRules.vue"
import FormSectionContent from "@/components/Policies/Forms/FormSectionContent.vue"
import FormRow from "@/components/Policies/Forms/FormRow.vue"
import TextInput from "@/components/TextInput.vue"
import FormContent from "@/components/Policies/Forms/FormContent.vue"

export default {
  components: {
    FormSectionContent,
    FormRow,
    TextInput,
    FormContent,
    ChangePasswordRules,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    confirmValue: {
      type: String,
      default: null,
    },
    isOneRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dirty: false,
    }
  },
  methods: {
    handleInput(value) {
      this.dirty = true
      this.$emit("input", value)
    },
    handleConfirmInput(value) {
      this.$emit("confirm-input", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation-container {
  height: 100%;
  width: 100%;
}
.loading-page-spinner {
  height: 19.51rem;
}

.change-password-label {
  padding: 1rem 0;

  .change-password-header {
    font-size: 1.6rem;
    color: var(--dark-grey);
  }
}
.change-password-rules {
  font-size: 1.2rem;
}
</style>
