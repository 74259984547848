











import Vue from "vue"
import RadioGroup from "./RadioGroup.vue"
import { FormGroupItem } from "../types/FormGroupItem"

export default Vue.extend({
  components: {
    RadioGroup,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    allowUnknown: {
      type: Boolean,
      default: null,
    },
    na: {
      type: Boolean,
      default: null,
    },
    asterisk: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    items(): FormGroupItem[] {
      const defaultItems = [this.getItem("Yes"), this.getItem("No")]

      if (this.allowUnknown) {
        return [...defaultItems, this.getItem("Unknown")]
      }

      if (this.na) {
        return [...defaultItems, this.getItem("NA")]
      }

      return defaultItems
    },
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    getItem(label: string) {
      return {
        key: label.toLowerCase(),
        label,
      }
    },
  },
})
