import { BillingIFrameResponse } from "@/types/BillingIframeResponse"
import { BillingLinkResponse } from "@/types/BillingLinkResponse"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getBillingUrl, getClientsUrl } from "@/util/urls"

export const getBillingLink = (pageRequested: string) =>
  makeAuthenticatedRequest<BillingLinkResponse>(`${getBillingUrl()}/${pageRequested}`, "GET")
export const getBillingIFrame = (pageRequested: string) =>
  makeAuthenticatedRequest<BillingIFrameResponse>(`${getBillingUrl()}/iframe/${pageRequested}`, "GET")
export const getBillingIFrameForClient = (pageRequested: string, clientKey: string) =>
  makeAuthenticatedRequest<BillingIFrameResponse>(
    `${getClientsUrl()}/${clientKey}/billing/iframe/${pageRequested}`,
    "GET"
  )
