<template>
  <div class="firm-users-table-view">
    <div class="firm-users-table-content">
      <div v-if="isLmncAdmin" class="firm-users-back-btn-container">
        <v-btn plain color="primary" class="firm-users-back-btn" @click="handleBackButton">
          <v-icon>mdi-chevron-left</v-icon>Back to Firm List
        </v-btn>
      </div>
      <SlideUpToggleTransition>
        <div v-if="firmName === null" key="loading" class="loading-page-spinner d-flex ma-4 justify-start">
          <v-progress-circular indeterminate color="primary" :size="60"></v-progress-circular>
        </div>
        <div v-else class="firm-info">
          <div key="header" class="firm-users-table-title">
            <h1>{{ firmName }}</h1>
            <YourPolicyBtn class="policy-btn" icon="plus" font-size="1.4rem" @click="handleAddUser"
              >Add User</YourPolicyBtn
            >
          </div>
          <div>
            <div class="client-info-header">Client Key: {{ client.clientKey }}</div>
            <div class="client-info-header">Client Status: {{ readableClientStatus }}</div>
          </div>
        </div>
      </SlideUpToggleTransition>
      <v-divider></v-divider>
      <LmncTable
        :loading="isLoading"
        :headers="headers"
        :items="itemsWithFormattedNames"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total-pages="totalPages"
        :total-count="userCount"
        item-key="id"
        @page-change="handlePageChange"
      >
        <template #item.isClientAdmin="{ item }">
          <div class="firm-admin-container">
            <v-switch
              class="toggle-switch"
              color="secondary"
              inset
              :disabled="item.id === currentUserId"
              :input-value="item.isClientAdmin"
              title="test"
              @change="handleUpdateUserRole(item, $event)"
            >
            </v-switch>
          </div>
        </template>
        <template #item.action="{ item }">
          <div class="action-btn-container">
            <YourPolicyBtn icon="edit" font-size="1.4rem" @click="handleEdit(item.id, item.name)">Edit</YourPolicyBtn>
            <YourPolicyBtn
              icon="minus"
              font-size="1.4rem"
              :disabled="item.id === currentUserId"
              @click="handleOpenRemoveDialog(item)"
            >
              Remove
            </YourPolicyBtn>
          </div>
        </template>
      </LmncTable>
    </div>
    <LmncDialog
      :open="removeUserDialogOpen"
      :is-loading="removeUserDialogIsLoading"
      :selected-user="removeSelectedUser"
      dialog-max-width="50rem"
      card-height="23rem"
      :page-number="pageNumber"
      :page-size="pageSize"
      :total-pages="totalPages"
      :disable-close-button="removeUserDialogIsLoading"
      @dialog-closed="handleRemoveUserDialogClose"
    >
      <div v-if="removeSelectedUser" class="remove-user-dialog-text-container">
        <div class="remove-user-dialog-text">Are you sure you want to remove this user?</div>
        <div class="remove-user-dialog-text">{{ removeSelectedUser.name }}</div>
      </div>

      <template #action-buttons>
        <Button variety="cancel" :disabled="removeUserDialogIsLoading" @click="handleRemoveUserDialogClose"
          >Cancel</Button
        >
        <Button variety="submit" :disabled="removeUserDialogIsLoading" @click="handleRemoveUser">Yes</Button>
      </template>
    </LmncDialog>
  </div>
</template>

<script>
import { DEFAULT_PAGE_SIZE } from "@/constants/defaultPageSize"
import { CLIENT_STATUS } from "@/constants/clientStatus"
import LmncTable from "@/components/LmncTable.vue"
import SlideUpToggleTransition from "@/components/SlideUpToggleTransition.vue"
import YourPolicyBtn from "@/components/Policies/YourPolicyInformation/YourPolicyBtn.vue"
import LmncDialog from "@/components/LmncDialog.vue"
import Button from "@/components/Button.vue"
import {
  getClientNameByClientKey,
  getUsersByClientKey,
  removeClientUser,
  updateClientUserById,
  getClientByClientKey,
} from "@/services/clientService"
import { UserRole } from "@/constants/userRoles"
import { currentUserStateStore } from "@/stores/currentUserStore"

export default {
  components: { LmncTable, YourPolicyBtn, LmncDialog, Button, SlideUpToggleTransition },
  props: {
    isLmncAdmin: {
      type: Boolean,
      default: null,
    },
    isClientAdmin: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    isLoading: true,
    errorOccurred: false,
    errorMessage: null,
    firmName: null,
    userData: [],
    hasNextPage: null,
    userCount: 0,
    totalPages: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: 1,
    paginated: true,
    sortBy: "firmName",
    sortDirection: "ASC",
    headers: [
      { text: "Firm Administrator", value: "isClientAdmin", sortable: false, class: "firms-header firm-admin" },
      { text: "", value: "name", sortable: false, class: "firms-header", width: "70%" },
      { text: "", value: "action", sortable: false, class: "firms-header" },
    ],
    removeUserDialogOpen: false,
    removeUserDialogIsLoading: false,
    removeSelectedUser: null,
    client: null,
    currentClientKey: null,
    currentUserId: currentUserStateStore.state.user.id,
    currentUser: currentUserStateStore.state.user,
  }),
  computed: {
    itemsWithFormattedNames() {
      if (!this.isLoading) {
        return this.userData.map(user => ({
          ...user,
          name: `${user.firstName} ${user.lastName}`,
          isClientAdmin: this.checkIsClientAdmin(user.role),
        }))
      }

      return []
    },
    readableClientStatus() {
      if (this.client === null) {
        return ""
      }

      return CLIENT_STATUS.get(this.client.status)
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.currentClientKey = this.$route.params.clientKey
      await this.initializeTable()
    })
  },
  methods: {
    checkIsClientAdmin(role) {
      return role === UserRole.CLIENT_ADMIN
    },
    async initializeTable() {
      if ([UserRole.CLIENT_ADMIN, UserRole.LMNC_ADMIN].includes(this.currentUser.role)) {
        this.client = await getClientByClientKey(this.currentClientKey)
        this.firmName = this.client.name
        this.userData = await this.fetchUsersByClientKey(this.currentClientKey)
        this.isLoading = false
      } else {
        this.$router.push("/")
      }
    },
    async fetchFirmNameByClientKey(clientKey) {
      const response = await getClientNameByClientKey(clientKey)
      return response
    },

    async fetchUsersByClientKey(clientKey) {
      this.isLoading = true
      const response = await getUsersByClientKey(clientKey, this.pageSize, this.pageNumber, true)
      this.isLoading = false
      return this.setuserDataForPagination(response)
    },

    setuserDataForPagination(value) {
      this.hasNextPage = value.hasNextPage
      this.userCount = value.total
      this.totalPages = Math.ceil(value.total / this.pageSize)
      return value.items
    },

    roleCheck() {
      if (this.isLmncAdmin === false && this.isClientAdmin === false) {
        this.$router.push("/policies")
      }
    },

    handleBackButton() {
      this.$router.push("/firms")
    },

    handleAddUser() {
      if (this.currentUser.role === UserRole.LMNC_ADMIN) {
        this.$router.push(`/firms/${this.currentClientKey}/dashboard/users/add-user`)
      } else {
        this.$router.push(`/firms/${this.currentClientKey}/add-user`)
      }
    },

    handleEdit(value) {
      if (this.currentUser.role === UserRole.LMNC_ADMIN) {
        this.$router.push(`/firms/${this.currentClientKey}/dashboard/users/${value}`)
      } else {
        this.$router.push(`/firms/${this.currentClientKey}/users/${value}`)
      }
    },
    handleOpenRemoveDialog(value) {
      this.removeUserDialogOpen = true
      this.removeSelectedUser = value
    },

    handleRemoveUserDialogClose() {
      this.removeUserDialogOpen = false
      this.removeSelectedUser = null
    },

    async handleRemoveUser() {
      this.removeUserDialogIsLoading = true
      this.isLoading = true
      try {
        await removeClientUser(this.removeSelectedUser.id)
        this.userData = this.userData.filter(userDataUser => userDataUser.id !== this.removeSelectedUser.id)
        this.$emit("updated-firm-users", {
          text: `Removed ${this.removeSelectedUser.firstName} ${this.removeSelectedUser.lastName}`,
          variety: "success",
        })
        this.handleRemoveUserDialogClose()
        this.removeUserDialogIsLoading = false
        this.isLoading = false
      } catch (error) {
        this.errorOccurred = true
        this.$emit("error", { text: "Something went wrong", variety: "error" })
        this.isLoading = false
      }
    },

    async handlePageChange(value) {
      this.pageNumber = value
      this.userData = await this.fetchUsersByClientKey(this.currentClientKey)
    },

    async handleUpdateUserRole(value, isClientAdmin) {
      try {
        const firmItemIndex = this.userData.findIndex(firmItem => firmItem.id === value.id)
        this.userData[firmItemIndex] = { ...this.userData[firmItemIndex], isClientAdmin }
        await updateClientUserById(value.id, { isClientAdmin })
      } catch (error) {
        this.$emit("updated-firm-users", { text: `${error}`, variety: "error" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.firm-users-table-view {
  height: 100%;
  width: 100%;

  .loading-page-spinner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .firm-users-back-btn {
    text-transform: none;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 4.3rem;
    letter-spacing: 0.03rem;
    padding: 0 0 1rem 0;
  }

  .firm-info {
    margin-bottom: 2rem;

    .client-info-header {
      font-size: 1.8rem;
      color: var(--light-blue);
    }

    .firm-users-table-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 2rem;
    }
  }

  .policy-btn {
    font-size: 1.4rem;
  }
}

.toggle-switch {
  &::v-deep {
    .v-application,
    .v-input--switch__thumb {
      color: white !important;
    }
    .v-input--switch__track {
      opacity: 1 !important;
    }
  }
}

.no-users {
  height: 100%;
  width: 100%;
  min-height: 40rem;
  font-size: 1.8rem;
  color: var(--primary);
}

.remove-user-dialog-text-container {
  .remove-user-dialog-text {
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--dark-grey);
  }
}
</style>

<style lang="scss">
.firms-header {
  font-size: 1.4rem !important;
  font-weight: 400;
  line-height: 1.9rem;
  color: var(--dark-grey) !important;

  i {
    font-size: 1.4rem !important;
  }
}

.firm-admin {
  span {
    padding-left: 1.5rem !important;
  }
}

.firm-admin {
  width: 14%;
}

.firm-admin-container {
  display: flex;
  justify-content: center;
}

.firms-action-btns {
  width: 15%;
}

.action-btn-container {
  display: flex;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>
