<template>
  <div
    :class="isOpen ? 'expansion-container expanded' : 'expansion-container'"
    :style="isOpen ? `min-height: ${minHeight}` : ''"
  >
    <FormRow v-if="isOpen" p-top>
      <template #column-1>
        <DocUploader
          v-if="documentValue"
          :value="documentValue"
          :category-key="documentValue[0].categoryKey"
          @input="handleDocumentValueInput"
        >
          Attach all documentation
        </DocUploader>
        <p v-if="explanationText" class="pl-4 explanation">{{ explanationText }}</p>
        <TextInput
          v-if="explanationKey"
          class="pl-4"
          label="Explanation"
          :value="explanationValue"
          @input="handleExplanationValueInput"
        ></TextInput>
      </template>
    </FormRow>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue"
import FormRow from "./FormRow.vue"
import DocUploader from "./DocUploader.vue"

export default {
  components: { FormRow, TextInput, DocUploader },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    explanationValue: {
      type: String,
      default: null,
    },
    explanationKey: {
      type: String,
      default: null,
    },
    documentValue: {
      type: Array,
      default: null,
    },
    documentKey: {
      type: Array,
      default: null,
    },
    minHeight: {
      type: String,
      default: "100%",
    },
    explanationText: {
      type: String,
      default: null,
    },
  },
  watch: {
    isOpen(currentValue, previousValue) {
      if (currentValue === false && previousValue === true) {
        this.handleResetInputs()
      }
    },
  },
  methods: {
    handleExplanationValueInput(value) {
      this.$emit("input", { key: `${this.explanationKey}`, type: "explanation", value })
    },

    handleDocumentValueInput(value) {
      this.$emit("input", { key: this.documentKey, type: "document", value })
    },

    handleResetInputs() {
      this.handleExplanationValueInput(null)

      if (this.documentValue) {
        this.handleDocumentValueInput([
          {
            base64Content: null,
            categoryKey: `${this.documentValue[0].categoryKey}`,
            fileName: null,
          },
        ])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.expansion-container {
  height: 0rem;
  opacity: 0;
  transition: 0.25s ease-in-out;
}

.expanded {
  height: 100%;
  opacity: 1;
  transition: 0.25s ease-in-out;
}

.explanation {
  font-size: 1.6rem;
}
</style>
