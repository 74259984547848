import Vue from "vue"
import { FormGroupItem } from "@/types/FormGroupItem"
import { getLetteredLabel } from "@/util/getLetteredLabel"

export const LetteredFormItemsMixin = Vue.extend({
  props: {
    items: {
      type: Array,
      required: true,
    },
    lettered: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    itemsWithLabel(): FormGroupItem[] {
      const radioGroupItems = this.items as FormGroupItem[]

      if (this.lettered) {
        return getLetteredLabel(radioGroupItems)
      }

      return radioGroupItems
    },
  },
})
