import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=e05ae772&scoped=true&"
import script from "./Loader.vue?vue&type=script&lang=ts&"
export * from "./Loader.vue?vue&type=script&lang=ts&"
import style0 from "./Loader.vue?vue&type=style&index=0&id=e05ae772&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e05ae772",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VProgressCircular})
