<template>
  <YourPolicyContent subtitle="Contact Information" divider :is-loading="isLoading">
    <template #title-action-buttons>
      <YourPolicyBtn edit @click="handleEdit">Edit/Update</YourPolicyBtn>
    </template>
    <template #content>
      <div class="contact-info-container">
        <div class="contact-info-content">
          <div class="contact-info">
            <div>
              <Subtitle>Name</Subtitle>
              <PolicyItemText>{{ name }}</PolicyItemText>
            </div>
            <Subtitle>Mailing Address</Subtitle>
            <PolicyItemText><Address :address="address" /></PolicyItemText>
          </div>

          <div class="contact-info">
            <Subtitle>Phone</Subtitle>
            <PolicyItemText>{{ phone }}</PolicyItemText>
          </div>
          <div>
            <Subtitle>Email</Subtitle>
            <PolicyItemText>{{ email }}</PolicyItemText>
          </div>
        </div>
      </div>
    </template>
  </YourPolicyContent>
</template>

<script>
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { getClientContactInfo } from "@/services/clientService"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { toTitleCaseWithAbbreviations } from "@/util/toTitleCase"
import Subtitle from "@/components/Subtitle.vue"
import Address from "@/components/Address.vue"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import YourPolicyBtn from "./YourPolicyBtn.vue"
import YourPolicyContent from "./YourPolicyContent.vue"
import PolicyItemText from "../PolicyDashboard/PolicyItemText.vue"

export default {
  components: { YourPolicyContent, YourPolicyBtn, Subtitle, PolicyItemText, Address },
  props: {
    clientKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      email: null,
      name: null,
      phone: null,
      address: null,
    }
  },
  mounted() {
    this.fetchContactInfo()
  },
  methods: {
    async fetchContactInfo() {
      safelyDoWithUserInfo(async user => {
        const response = await getClientContactInfo(getClientKeyFromUserOrUrl(this, user))

        this.email = response.email
        this.phone = response.phone
        this.address = response.address
        this.name = toTitleCaseWithAbbreviations(response.name)
        this.isLoading = false
      })
    },
    async handleEdit() {
      safelyDoWithUserInfo(async user => {
        this.$router.push(
          await appendFirmsIfAdminViewingClient(`/update-contact-info`),
          getClientKeyFromUserOrUrl(this, user)
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-info-container {
  .contact-info-content {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .contact-info {
      min-width: 35rem;

      div {
        margin-bottom: 2rem;
      }
    }
  }
}
</style>
@/util/withClientKey
