import Vue from "vue"

export const GetClientKey = Vue.extend({
  data: () => ({
    clientKey: null as null | string,
  }),
  mounted() {
    const clientKeyInUrl = this.$route.params.clientKey

    this.clientKey = clientKeyInUrl || this.$props.currentUser.clientKey
  },
})
