
















import { validateRequired } from "@/validators/validateRequired"
import Vue from "vue"
import { LetteredFormItemsMixin } from "../mixins/letteredFormItemsMixin"

export default Vue.extend({
  mixins: [LetteredFormItemsMixin],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    column: {
      type: Boolean,
      default: true,
    },
    row: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      validateRequired,
    }
  },
  computed: {
    radioClass(): string {
      return this.row ? "row" : "column"
    },
  },
  methods: {
    handleInput(newItemValue: string, toggled: boolean, newItemExclusive: boolean) {
      const newValue = newItemExclusive ? [newItemValue] : [...this.value, newItemValue]

      if (toggled) {
        this.$emit("input", newValue)
      } else {
        this.$emit(
          "input",
          this.value.filter(item => item !== newItemValue)
        )
      }
    },
  },
})
