import { currentUserStateStore } from "@/stores/currentUserStore"
import { UserRecordWithClient } from "@/types/UserRecord"

export const safelyDoWithUserInfo = async (doFnc: (user: UserRecordWithClient) => Promise<void>) => {
  if (currentUserStateStore.state.user == null) {
    throw new Error("An attempt was made to fetch data using user information before user information was retrieved")
  } else {
    await doFnc(currentUserStateStore.state.user as UserRecordWithClient)
  }
}
