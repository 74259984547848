import { render, staticRenderFns } from "./ChangeAddress.vue?vue&type=template&id=001e8806&scoped=true&"
import script from "./ChangeAddress.vue?vue&type=script&lang=ts&"
export * from "./ChangeAddress.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeAddress.vue?vue&type=style&index=0&id=001e8806&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "001e8806",
  null
  
)

export default component.exports