<template>
  <div class="lmnc-admin-dashboard">
    <div v-if="isLoading" class="loading-page-spinner d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="80"></v-progress-circular>
    </div>
    <transition v-else name="route" mode="out-in">
      <router-view
        :is-lmnc-admin="isLmncAdmin"
        :is-client-admin="isClientAdmin"
        :current-user="currentUser"
        class="view"
        @updated-firm-users="handleEventResponse"
      />
    </transition>
    <LmncSnackbar :value="isSnackbarOpen" :text="resultText" :variety="resultVariety" @close="handleSnackbarClose" />
  </div>
</template>

<script>
import LmncSnackbar from "@/components/LmncSnackbar.vue"

export default {
  components: { LmncSnackbar },
  props: {
    isLmncAdmin: {
      type: Boolean,
      default: null,
    },
    isClientAdmin: {
      type: Boolean,
      default: null,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: true,
    isSnackbarOpen: false,
    resultText: null,
    resultVariety: null,
  }),
  mounted() {
    this.$nextTick(() => {
      this.roleCheck()
      this.isLoading = false
    })
  },
  methods: {
    roleCheck() {
      if (this.isLmncAdmin === false && this.isClientAdmin === false) {
        this.$router.push("/policies")
      }
    },
    handleEventResponse(value) {
      this.resultText = value.text
      this.resultVariety = value.variety
      this.isSnackbarOpen = true
    },
    handleSnackbarClose() {
      this.isSnackbarOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.lmnc-admin-dashboard {
  height: 100%;
  width: 100%;
}
</style>
