





















































import Vue from "vue"
import createNumberMask from "text-mask-addons/dist/createNumberMask"
import SlideUpToggleTransition from "./SlideUpToggleTransition.vue"
import { validateRequired } from "../validators/validateRequired"

const CURRENCY_MASK = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
})

const PHONE_MASK = "(###) ###-####"

export default Vue.extend({
  components: { SlideUpToggleTransition },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    smallLabel: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    phone: {
      type: Boolean,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: null,
    },
    on: {
      type: Object,
      default: null,
    },
    attrs: {
      type: Object,
      default: null,
    },
    redLabel: {
      type: Boolean,
      default: null,
    },
    percentage: {
      type: Boolean,
      default: null,
    },
    currency: {
      type: Boolean,
      default: null,
    },
    labelWrap: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: null,
    },
    numberOnly: {
      type: Boolean,
      default: null,
    },
    animateLabel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: null,
    },
    error: {
      type: Boolean,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    verified: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    validateOnBlur: {
      type: Boolean,
      default: true,
    },
    underlined: {
      type: Boolean,
      default: null,
    },
    searchIcon: {
      type: Boolean,
      default: null,
    },
    allowClear: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    validateRequired,
  }),
  computed: {
    mask() {
      if (this.phone) {
        return PHONE_MASK
      }

      if (this.currency) {
        return CURRENCY_MASK
      }

      return null
    },
    inputClass(): string {
      return `lmnc-input${this.labelWrap ? " label-wrap" : ""}${this.underlined ? " underlined" : ""}`
    },
    showClear(): boolean {
      return this.allowClear !== null && typeof this.value === "string" && this.value.length > 0
    },
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value)
    },
    handleClear() {
      this.$emit("input", "")
    },
    handleBlur(event: FocusEvent) {
      this.$emit("blur", event)
    },
    isNumber(event: KeyboardEvent) {
      const target = event.target as HTMLTextAreaElement
      const expect = target.value.toString() + event.key.toString()
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        event.preventDefault()
        return false
      }
      return true
    },
  },
})
