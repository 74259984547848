




import Vue from "vue"
import Button from "./Button.vue"

export default Vue.extend({
  components: {
    Button,
  },
  props: {
    buttonLabel: {
      type: String,
      default: "Submit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick($event: Event) {
      this.$emit("click", $event)
    },
  },
})
