<template>
  <div class="policy-information-container">
    <div class="policy-information-title-bar">
      <h1 v-if="title" class="policy-title">{{ title }}</h1>
      <h3 v-else-if="!title && subtitle" class="policy-subtitle">{{ subtitle }}</h3>
      <div class="title-action-bar">
        <slot name="title-action-buttons"></slot>
      </div>
    </div>
    <div class="content-wrapper">
      <Loader :is-loading="isLoading">
        <slot name="content" :policy-key="policyKey" :policy="policy" :client-key="clientKey"></slot>
      </Loader>
    </div>
    <v-divider v-if="divider" style="margin-top: 3rem"></v-divider>
  </div>
</template>

<script>
import Vue from "vue"
import Loader from "@/components/Loader.vue"

export default Vue.extend({
  components: {
    Loader,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    divider: {
      type: Boolean,
      default: null,
    },
    policy: {
      type: Object,
      default: null,
    },
    clientKey: {
      type: String,
      default: null,
    },
    policyKey: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.policy-information-container {
  .content-wrapper {
    min-height: 24rem;
  }
  .policy-information-title-bar {
    display: flex;

    .title-action-bar {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 0 1rem;
    }
    .policy-title {
      font-weight: 400;
    }

    .policy-subtitle {
      padding: 4rem 0 1rem 0;
      color: var(--light-blue);
      font-weight: 500;
      letter-spacing: 0.3px;
    }
  }
}
</style>
