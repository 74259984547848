








































































































































































































































































































































































































































































































































































































































































































import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { intellectualPropertyApplication } from "@/constants/policyApplicationForms"
import Vue from "vue"
import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import TextArea from "../../TextArea.vue"
import TextInput from "../../TextInput.vue"
import YesNoRadioGroup from "../../YesNoRadioGroup.vue"
import Form from "../Forms/Form.vue"
import FormBody from "../Forms/FormBody.vue"
import FormContent from "../Forms/FormContent.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import FormDetail from "../Forms/FormDetail.vue"
import FormRow from "../Forms/FormRow.vue"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import ListedQuestion from "../Forms/ListedQuestion.vue"
import DocUploader from "../Forms/DocUploader.vue"
import AttorneyInsuredSelect from "../../AttorneyInsuredSelect.vue"

export default (
  Vue as import("vue").VueConstructor<Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin>
).extend({
  components: {
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    YesNoRadioGroup,
    TextArea,
    FormDatePicker,
    ListedQuestion,
    FormContent,
    FormBody,
    FormDetail,
    DocUploader,
    AttorneyInsuredSelect,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(true)],
  data: () => ({
    DOCUMENT_CATEGORY_KEY,
    formToSubmit: intellectualPropertyApplication,
    attorneyNamesList: "",
    userSubmissionForm: {
      type: "intellectualProperty",
      items: {
        firmname: null,
        policykey: null,
        intellectualPropertyLawAttorneyNames: null as null | string,
        percentageDomesticPatent: null,
        percentageForeignPatentApplication: null,
        percentageDomesticPatentLitigation: null,
        percentageForeignPatentLitigation: null,
        percentageDomesticPatentSearches: null,
        perentageForeignPatentSearches: null,
        percentageTrademarkCopyrightRegistration: null,
        otherPracticeAreaName: null,
        otherPracticeAreaPercentage: null,
        percentageSpecializationChemical: null,
        percentageSpecializationComputers: null,
        percentageSpecializationElectronic: null,
        percentageSpecializationMechanical: null,
        percentageSpecializationMedical: null,
        otherSpecializationName: null,
        otherSpecializationPercentage: null,
        clientOneName: null,
        clientOneTypeOfBusiness: null,
        clientOneLegalServicesProvided: null,
        clientTwoName: null,
        clientTwoTypeOfBusiness: null,
        clientTwoLegalServicesProvided: null,
        clientThreeName: null,
        clientThreeTypeOfBusiness: null,
        clientThreeLegalServicesProvided: null,
        percentageClientsFortune500: null,
        percentageClientsOtherPublicyTraded: null,
        percentageClientsPrivatelyHeld: null,
        percentageClientsIndividualInventors: null,
        otherClientPercentage: null,
        otherClientType: null,
        sendTerminationLetters: null,
        responsibilityForMaintenanceAnnuity: null,
        responsibilityForMaintenanceAnnuityExplanation: null,
        thirdParties: null,
        thirdPartiesExplanation: null,
        foreignFilingDeadlinesRequirements: null,
        engagementLetter: null,
        qualifyOpinion: null,
        litigatedForMattersAlsoHandling: null,
        acknowledgeTerritoriesAndCountries: null,
        acknowledgeTerritoriesAndCountriesExplanation: null,
        signature: {
          signature: null,
          signDate: null,
        },
      },
    },
    userSubmissionDocuments: {
      attorneyDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    },
  }),
  methods: {
    handleAttorneyListUpdated(value: string) {
      this.userSubmissionForm.items.intellectualPropertyLawAttorneyNames = value
    },
  },
})
