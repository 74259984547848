
































import LmncSnackbar from "@/components/LmncSnackbar.vue"
import { GetClientKey } from "@/mixins/getClientKey"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import Vue from "vue"

type PolicyLink = {
  tab: string
  to: string
  onClick?: () => void
}

export default Vue.extend({
  components: { LmncSnackbar },
  mixins: [GetClientKey],
  props: {
    currentUser: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    tab: null as null | string,
    items: null as null | PolicyLink[],
    isLoading: false,
    policyKey: null as any,
    clientKey: null as any,
    isSnackbarOpen: false as boolean,
    resultText: null as null | string,
    resultVariety: null as any,
    tabs: null as null | any,
  }),
  async mounted() {
    this.$nextTick(async () => {
      this.policyKey = this.$route.params.policyKey as string
      this.tabs = [
        {
          label: "Your policy",
          to: await appendFirmsIfAdminViewingClient(`/policies/${this.policyKey}`, this.clientKey),
        },
        {
          label: "Policy Documents",
          to: await appendFirmsIfAdminViewingClient(`/policies/${this.policyKey}/policy-documents`, this.clientKey),
        },
        {
          label: "Make a policy change",
          to: await appendFirmsIfAdminViewingClient(
            `/policies/${this.$route.params.policyKey}/policy-change`,
            this.clientKey
          ),
          click: async () =>
            this.$router.push(
              await appendFirmsIfAdminViewingClient(
                `/policies/${this.$route.params.policyKey}/policy-change/add-attorney`,
                this.clientKey
              )
            ),
        },
        {
          label: "Quotes",
          to: await appendFirmsIfAdminViewingClient(`/policies/${this.policyKey}/quotes`, this.clientKey),
        },
      ]
    })
  },
  methods: {
    handlePolicySubmission(value: any) {
      this.isLoading = true
      this.resultVariety = value.variety
      this.resultText = value.text
      this.isSnackbarOpen = true
      this.isLoading = false
    },

    handleSnackbarClose() {
      this.isSnackbarOpen = false
    },
  },
})
