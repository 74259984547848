<template>
  <div>
    <v-data-table
      class="lmnc-table"
      :headers="headers"
      :items="coalescedItems"
      :item-key="itemKey"
      :items-per-page="pageSize"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :page="pageNumber"
      :server-items-length="totalCount"
      :loading="loading"
      loading-text="Loading..."
      :hide-default-header="hideDefaultHeader"
      must-sort
      hide-default-footer
      @update:sort-by="handleSortByChange"
      @update:sort-desc="handleSortDirectionChange"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-data-table>
    <div class="pagination-container">
      <v-pagination
        class="table-pagination"
        :value="pageNumber"
        :length="totalPages"
        :total-visible="6"
        color="primary"
        @input="handlePageChange($event)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    freezeFirstColumn: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: null,
    },
    noDataText: {
      type: String,
      default: "No records found",
    },
    noResultsText: {
      type: String,
      default: "No results found",
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: undefined,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    coalescedItems() {
      return this.items === null ? [] : this.items
    },
  },
  watch: {
    headersToRender() {
      if (this.editable === true) {
        return this.headers.map(header => {
          if (header.text === "Name") {
            return { ...header, sortable: true }
          }
          return header
        })
      }
      return this.headers
    },
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    handlePageChange(value) {
      window.scroll({ top: 0, behavior: "smooth" })

      this.$emit("page-change", value)
    },
    handleSortByChange(value) {
      this.$emit("sort-by-change", value)
    },
    handleSortDirectionChange(value) {
      this.$emit("sort-desc-change", value)
    },
  },
}
</script>

<style lang="scss" scoped>
.lmnc-table::v-deep {
  background-color: transparent !important;

  td {
    font-size: 2.2rem !important;
    line-height: 3.2rem;
    color: var(--dark-grey);
  }

  thead {
    height: 4px !important;
  }
}

.pagination-container {
  display: flex;
  justify-content: flex-end;

  &::v-deep {
    .v-pagination__navigation,
    .v-pagination__item {
      box-shadow: none;
      margin: 0;
      padding: 0;
      font-size: 1.4rem;
      color: var(--grey) !important;
    }

    .v-pagination__item--active {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--primary) !important;
      box-shadow: none;
      margin: 0;
      padding: 0;
      font-size: 1.4rem;
    }
  }
}
</style>
