
























import Vue from "vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getPolicyAttorneysInsured } from "@/services/policyService"
import { toTitleCaseWithAbbreviations } from "@/util/toTitleCase"
import { AttorneyInsured } from "@/types/AttorneyInsured"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import ViewAllBtn from "./ViewAllBtn.vue"
import YourPolicyContent from "./YourPolicyContent.vue"
import YourPolicyBtn from "./YourPolicyBtn.vue"

export default Vue.extend({
  components: { YourPolicyContent, YourPolicyBtn, ViewAllBtn },
  props: {
    policyKey: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    viewAllAttorneys: false,
    attorneys: null as null | AttorneyInsured[],
  }),
  computed: {
    isLoading(): boolean {
      return this.attorneys === null
    },
  },
  mounted() {
    this.fetchAttorneysInsured()
  },
  methods: {
    async fetchAttorneysInsured() {
      await safelyDoWithUserInfo(async user => {
        this.attorneys = await getPolicyAttorneysInsured(getClientKeyFromUserOrUrl(this, user), this.policyKey)
      })
    },
    handleViewAll() {
      this.viewAllAttorneys = !this.viewAllAttorneys
    },
    async handleAddAttorney() {
      this.$router.push(
        await appendFirmsIfAdminViewingClient(
          `/policies/${this.$route.params.policyKey}/policy-change/add-attorney`,
          this.$route.params.clientKey
        )
      )
    },
    async handleRemoveAttorney() {
      this.$router.push(
        await appendFirmsIfAdminViewingClient(
          `/policies/${this.$route.params.policyKey}/policy-change/remove-attorney`,
          this.$route.params.clientKey
        )
      )
    },
    toTitleCase(name: string) {
      return toTitleCaseWithAbbreviations(name)
    },
  },
})
