import { PolicyForm } from "@/types/PolicyFormTypes"
import { DOCUMENT_CATEGORY_KEY } from "./categoryKey"

export const additionalAttorneyForm: PolicyForm = {
  type: "attorneyAddition",
  description: "Request for Additional Attorney",
  items: [
    {
      name: "firmname",
      description: "Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "policykey",
      description: "Policy Key",
      inputType: "text",
      value: null,
    },
    {
      name: "officeaddress",
      description: "Office Address",
      inputType: "text",
      value: null,
    },
    {
      name: "city",
      description: "City",
      inputType: "text",
      value: null,
    },
    {
      name: "state",
      description: "State",
      inputType: "dropdown",
      value: null,
    },
    {
      name: "zipcode",
      description: "Zip Code",
      inputType: "text",
      value: null,
    },
    {
      name: "attorneyname",
      description: "Name of Additional Attorney",
      inputType: "text",
      value: null,
    },
    {
      name: "datejoinedaslicensedattorney",
      description: "Date Joined as a Licensed Attorney",
      inputType: "date",
      value: null,
    },
    {
      name: "positionwithinfirm",
      description: "Position Within Firm",
      inputType: "text",
      value: null,
    },
    {
      name: "estimatedhoursworkedperweek",
      description: "Estimated Hours to be Worked/Week",
      inputType: "text",
      value: null,
    },
    {
      name: "areaofpractice",
      description: "Area of Practice, if known",
      inputType: "text",
      value: null,
    },
    {
      name: "prioractscoverage",
      description:
        "If this attorney was previously or is currently insured by Lawyers Mutual, does the firm want the attorney to be considered for Prior Acts Coverage?*",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "dateofbirth",
      description: "Date of Birth",
      inputType: "date",
      value: null,
    },
    {
      name: "gender",
      description: "Gender",
      inputType: "dropdown",
      value: null,
    },
    {
      name: "officeemail",
      description: "Office Email",
      inputType: "text",
      value: null,
    },
    {
      name: "lawschoolname",
      description: "Law School Name",
      inputType: "text",
      value: null,
    },
    {
      name: "yearnorthcarolina",
      description: "North Carolina Licence Bar Year",
      inputType: "text",
      value: null,
    },
    {
      name: "barnumbernorthcarolina",
      description: "North Carolina Licence Bar Number",
      inputType: "text",
      value: null,
    },
    {
      name: "additonallicenseyear",
      description: "Additional License Information Year",
      inputType: "text",
      value: null,
    },
    {
      name: "additonallicensestate",
      description: "Additional License Information State",
      inputType: "text",
      value: null,
    },
    {
      name: "additonallicensebarnumber",
      description: "Additional License Information Bar Number",
      inputType: "text",
      value: null,
    },
    {
      name: "additonallicenseyear2",
      description: "Additional License Information Year",
      inputType: "text",
      value: null,
    },
    {
      name: "additonallicensestate2",
      description: "Additional License Information State",
      inputType: "text",
      value: null,
    },
    {
      name: "additonallicensebarnumber2",
      description: "Additional License Information Bar Number",
      inputType: "text",
      value: null,
    },
    {
      name: "priorcarrier",
      description: "Previous Insurance Carrier",
      inputType: "text",
      value: null,
    },
    {
      name: "priorcarriereffdate",
      description: "Previous Insurance Effective Date",
      inputType: "date",
      value: null,
    },
    {
      name: "previousemployername",
      description: "Previous Employer Name",
      inputType: "text",
      value: null,
    },
    {
      name: "refusedadmissionind",
      description:
        "Has the applicant attorney ever been refused admission to practice, reprimanded, disbarred, or suspended (including voluntary suspension) by any court or State Bar?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "refusedadmissiondetails",
      inputType: "text",
      value: null,
    },
    {
      name: "felonyconvictionind",
      value: null,
    },
    {
      name: "felonyconvictiondetails",
      value: null,
    },
    {
      name: "grievanceind",
      description:
        "Is the applicant attorney aware of any grievance, or sanction awarded against them, with any court, or administrative agency, State Bar, or other regulatory body?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "grievancedetails",
      description: "Additional Information",
      inputType: "text",
      value: null,
    },
    {
      name: "claimsagainstind",
      description:
        "Is the applicant attorney aware of any professional liability claim(s) or suit(s) made against you, or action filed against you, or claim paid on behalf of you?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "claimsagainstdetails",
      inputType: "text",
      value: null,
    },
    {
      name: "erroromissionind",
      description:
        "Is the applicant attorney aware of any circumstance, act, error, or omission which could result in a professional liability claim against them?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "erroromissiondetails",
      inputType: "text",
      value: null,
    },
    {
      name: "insurancedeclinedind",
      description:
        "Has the applicant attorney ever had any prior professional liability insurance coverage declined, cancelled, non-renewed, or offered with a deductible clause higher than standard, or premium surcharge, because of claims?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "insurancedeclineddetails",
      description: "",
      inputType: "text",
      value: null,
    },
    {
      name: "partnerassociateind",
      description:
        "Is the applicant attorney a partner, associate, employed lawyer, of counsel, independent contractor, or contract lawyer of a law firm other than this firm?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "partnerassociatedetails",
      description: "",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "contractorind",
      description:
        "Is the applicant attorney an independent contractor, contract lawyer, or employee of any entity other than this firm?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "contractordetails",
      description: "",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "guaranteedresultsind",
      description:
        "Has the applicant attorney ever entered into any contract or agreement, written or oral, guaranteeing results of any professional service rendered by them or by persons under their supervision?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "guaranteedresultsdetails",
      description: "",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "outofstateind",
      description:
        "Does the applicant attorney plan on soliciting and/or representing clients in matters or handling cases in states other than North Carolina?",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "outofstatedetails",
      description: "",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "newhireattorneysignature",
      description: "Newly Hired Attorney or Representative Signature",
      inputType: "text",
      value: null,
    },
    {
      name: "newhireattorneysignaturedate",
      description: "Newly Hired Attorney or Representative Signing Date",
      inputType: "date",
      value: null,
    },
    {
      name: "managingattorneysignaturedate",
      description: "Managing Attorney or Representative Signing Date",
      inputType: "date",
      value: null,
    },
    {
      name: "managingattorneysignature",
      description: "Managing Attorney or Representative Signature",
      inputType: "text",
      value: null,
    },
  ],
  documents: [
    {
      fileName: null,
      base64Content: null,
      categoryKey: DOCUMENT_CATEGORY_KEY,
    },
  ],
}

export const attorneyRemovalForm: PolicyForm = {
  type: "attorneyRemoval",
  description: "Request for Attorney Removal",
  items: [
    {
      name: "firmname",
      description: "Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "policykey",
      description: "Policy Key",
      inputType: "text",
      value: null,
    },
    {
      name: "officeaddress",
      description: "Office Address",
      inputType: "text",
      value: null,
    },
    {
      name: "city",
      description: "City",
      inputType: "text",
      value: null,
    },
    {
      name: "state",
      description: "State",
      inputType: "dropdown",
      value: null,
    },
    {
      name: "zipcode",
      description: "Zip Code",
      inputType: "text",
      value: null,
    },
    {
      name: "departingattorneyname",
      description: "Name of Departing Attorney",
      inputType: "text",
      value: null,
    },
    {
      name: "datetoberemoved",
      description: "Date attorney is to be removed from the Policy",
      inputType: "date",
      value: null,
    },
    {
      name: "firmnotifieddepartingattorney",
      description: "Firm Notified Departing Attorney",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "departingattorneyretiring",
      description: "Departing Attorney Retiring",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "departingattorneyleavingpractice",
      description: "Departing Attorney Leaving Practice",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "departingattorneymovingoutofnc",
      description: "Departing Attorney Moving Out of NC",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "departingattorneypracticeonown",
      description: "Departing Attorney Practice on Own",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "departingattorneyjoinanotherfirm",
      description: "Departing Attorney Join Another Firm",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "newfirmname",
      description: "New Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "departingattorneydeceased",
      description: "Departing Attorney Deceased",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "forwardingaddress",
      description: "Forwarding Address",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingcity",
      description: "Forwarding address City",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingstate",
      description: "Forwarding address State",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingzip",
      description: "Forwarding address Zip",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingphonenumber",
      description: "Phone Number",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingemail",
      description: "Email",
      inputType: "text",
      value: null,
    },
    {
      name: "authorizedrepsigndate",
      description: "Authorized Owner Officer Partner or Representative Signing Date",
      inputType: "date",
      value: null,
    },
    {
      name: "authorizedrepsignature",
      description: "Authorized Owner Officer Partner or Representative Signature",
      inputType: "text",
      value: null,
    },
  ],
}

export const addressChangeForm: PolicyForm = {
  type: "addressChange",
  description: "Change of Address",
  items: [
    {
      name: "policykey",
      description: "Policy Key",
      inputType: "text",
      value: null,
    },
    {
      name: "priorAddressType",
      description: "Prior address type",
      inputType: "dropdown",
      value: null,
    },
    {
      name: "priorstreetorpobox",
      description: "Prior address street or PO box",
      inputType: "text",
      value: null,
    },
    {
      name: "priorcity",
      description: "Prior City",
      inputType: "text",
      value: null,
    },
    {
      name: "priorstate",
      description: "Prior State",
      inputType: "text",
      value: null,
    },
    {
      name: "priorzip",
      description: "Prior Zip",
      inputType: "text",
      value: null,
    },
    {
      name: "newaddress",
      description: "Is The New Address",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "newAddressType",
      description: "New address type",
      inputType: "dropdown",
      value: null,
    },
    {
      name: "newstreetorpobox",
      description: "New Street or PO Box",
      inputType: "text",
      value: null,
    },
    {
      name: "newcity",
      description: "New City",
      inputType: "text",
      value: null,
    },
    {
      name: "newstate",
      description: "New State",
      inputType: "text",
      value: null,
    },
    {
      name: "newzip",
      description: "New Zip",
      inputType: "text",
      value: null,
    },
    {
      name: "effectivedate",
      description: "Effective Date",
      inputType: "date",
      value: null,
    },
    {
      name: "firmwebsite",
      description: "Firm Website",
      inputType: "text",
      value: null,
    },
    {
      name: "businessnumber",
      description: "Business Number (if new)",
      inputType: "text",
      value: null,
    },
    {
      name: "faxnumber",
      description: "Fax Number (if new)",
      inputType: "text",
      value: null,
    },
    {
      name: "partnersigndate",
      description: "Partner Director Officer Owner Representative Date",
      inputType: "date",
      value: null,
    },
    {
      name: "partnersignature",
      description: "Partner Director Officer Owner Representative Signature",
      inputType: "text",
      value: null,
    },
  ],
}

export const businessNameChange: PolicyForm = {
  type: "businessNameChange",
  description: "Change of Business Name",
  items: [
    {
      name: "priorfirmname",
      description: "Prior Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "policykey",
      description: "Policy Key",
      inputType: "text",
      value: null,
    },
    {
      name: "newfirmname",
      description: "New Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "newstreetorpobox",
      description: "Forwarding address street or PO box",
      inputType: "text",
      value: null,
    },
    {
      name: "newcounty",
      description: "Forwarding address City",
      inputType: "text",
      value: null,
    },
    {
      name: "newcity",
      description: "Forwarding address City",
      inputType: "text",
      value: null,
    },
    {
      name: "newstate",
      description: "Forwarding address State",
      inputType: "text",
      value: null,
    },
    {
      name: "newzip",
      description: "Forwarding address Zip",
      inputType: "text",
      value: null,
    },
    {
      name: "effectivedate",
      description: "Effective Date",
      inputType: "date",
      value: null,
    },
    {
      name: "firmwebsite",
      description: "Firm Website",
      inputType: "text",
      value: null,
    },
    {
      name: "policyversion",
      description: "Policy Version",
      inputType: "text",
      value: null,
    },
    {
      name: "partnersigndate",
      description: "Partner Director Officer Owner Representative Date",
      inputType: "date",
      value: null,
    },
    {
      name: "partnersignature",
      description: "Partner Director Officer Owner Signature",
      inputType: "text",
      value: null,
    },
  ],
  documents: [
    {
      fileName: null,
      base64Content: null,
      categoryKey: DOCUMENT_CATEGORY_KEY,
    },
  ],
}

export const policyCancellationForm: PolicyForm = {
  type: "policyCancellation",
  description: "Request for Policy Cancellation",
  items: [
    {
      name: "firmname",
      description: "Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "policykey",
      description: "Policy Key",
      inputType: "text",
      value: null,
    },
    {
      name: "requestedcancellationdate",
      description: "Requested Cancellation Date",
      inputType: "date",
      value: null,
    },
    {
      name: "reasonfirmdissolving",
      description: "Reason Firm Dissolving",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "reasonretiring",
      description: "Reason Retiring",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "reasonleavingpractice",
      description: "Reason Leaving Private Practice",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "additionalinformation",
      description: "Additional Information",
      inputType: "text",
      value: null,
    },
    {
      name: "reasonmoveoutofnc",
      description: "Reason Moving Out of NC",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "reasonjoinanotherfirm",
      description: "Reason Joining Another Firm",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "newfirmname",
      description: "New Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "reasondeceased",
      description: "Reason Deceased",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "reasonother",
      description: "Reason Other",
      inputType: "text",
      value: null,
    },

    {
      name: "forwardingstreetorpobox",
      description: "Forwarding address street or PO box",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingcity",
      description: "Forwarding address City",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingstate",
      description: "Forwarding address State",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingzip",
      description: "Forwarding address Zip",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingphonenumber",
      description: "Phone Number",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingemail",
      description: "Email",
      inputType: "text",
      value: null,
    },
    {
      name: "partnersigndate",
      description: "Partner Director Officer Owner Representative Signature Date",
      inputType: "date",
      value: null,
    },
    {
      name: "partnersignature",
      description: "Partner Director Officer Owner Representative Signature",
      inputType: "text",
      value: null,
    },
  ],
}

export const extendedReportingForm: PolicyForm = {
  type: "extendedReporting",
  description: "Request for ERE (Tail Coverage) Quote",
  items: [
    {
      name: "applicantname",
      description: "Name of Applicant",
      inputType: "text",
      value: null,
    },
    {
      name: "applicantbarnumber",
      description: "Applicant Bar Number",
      inputType: "text",
      value: null,
    },
    {
      name: "departingfirmname",
      description: "Departing Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "policykey",
      description: "Policy Key",
      inputType: "text",
      value: null,
    },
    {
      name: "departuredate",
      description: "Departure Date",
      inputType: "date",
      value: null,
    },
    {
      name: "reasonretiring",
      description: "Reason Retiring",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "reasonleavingpractice",
      description: "Reason Leaving Private Practice",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "additionalinformation",
      description: "Additional Information",
      inputType: "text",
      value: null,
    },
    {
      name: "reasonmoveoutofnc",
      description: "Reason Moving Out of NC",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "reasonjoinanotherfirm",
      description: "Reason Joining Another Firm",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "newfirmname",
      description: "New Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "reasondeceased",
      description: "Reason Deceased",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "reasonother",
      description: "Reason Other",
      inputType: "text",
      value: null,
    },
    {
      name: "applicantsuspended",
      description:
        "Has any applicant’s right to practice law in North Carolina been suspended or revoked or has an investigation or disciplinary proceeding(s) which could result in suspension or disbarment been instituted by the North Carolina State Bar? ",
      inputType: "checkbox",
      value: null,
    },
    {
      name: "forwardingname",
      description: "Forwarding Name",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingstreetorpobox",
      description: "Forwarding address street or PO box",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingcity",
      description: "Forwarding address City",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingstate",
      description: "Forwarding address State",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingzip",
      description: "Forwarding address Zip",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingphonenumber",
      description: "Phone Number",
      inputType: "text",
      value: null,
    },
    {
      name: "forwardingemail",
      description: "Email",
      inputType: "text",
      value: null,
    },
    {
      name: "policyversion",
      description: "Policy Version",
      inputType: "text",
      value: null,
    },
    {
      name: "estatesigndate",
      description: "Estate Sign Date",
      inputType: "date",
      value: null,
    },
    {
      name: "estatesignature",
      description: "Estate Signature",
      inputType: "text",
      value: null,
    },
  ],
}

export const midTermLimitChangeForm: PolicyForm = {
  type: "midTermLimitChange",
  description: "Request Mid-Term Limit Change",
  items: [
    {
      name: "firmname",
      description: "Firm Name",
      inputType: "text",
      value: null,
    },
    {
      name: "policykey",
      description: "Policy Key",
      inputType: "text",
      value: null,
    },
    {
      name: "officeaddress",
      description: "Office Address",
      inputType: "text",
      value: null,
    },
    {
      name: "city",
      description: "City",
      inputType: "text",
      value: null,
    },
    {
      name: "state",
      description: "State",
      inputType: "dropdown",
      value: null,
    },
    {
      name: "zipcode",
      description: "Zip Code",
      inputType: "text",
      value: null,
    },
    {
      name: "limitofliability",
      description: "Limit of Liability Requested",
      inputType: "text",
      value: null,
    },
    {
      name: "deductible",
      description: "Deductible Requested",
      inputType: "text",
      value: null,
    },
    {
      name: "effectivedateofchange",
      description: "Effective Date of Change",
      inputType: "text",
      value: null,
    },
    {
      name: "changereason",
      description: "Reason for Change",
      inputType: "text",
      value: null,
    },
    {
      name: "policyversion",
      description: "Policy Version",
      inputType: "text",
      value: null,
    },
    {
      name: "authorizedrepsigndate",
      description: "Authorized Owner Officer Partner or Representative Signing Date",
      inputType: "date",
      value: null,
    },
    {
      name: "authorizedrepsignature",
      description: "Authorized Owner Officer Partner or Representative Signature",
      inputType: "text",
      value: null,
    },
  ],
}

export const attorneyNameChangeForm: PolicyForm = {
  type: "attorneyNameChange",
  description: "Attorney Name Change",
  items: [
    {
      name: "attorneynamecurrent",
      description: "Attorney Name Current",
      inputType: "text",
      value: null,
    },
    {
      name: "attorneynamenew",
      description: "Attorney Name New",
      inputType: "text",
      value: null,
    },
    {
      name: "namechangereason",
      description: "Attorney Name Change Reason",
      inputType: "text",
      value: null,
    },
    {
      name: "attorneyorrepsigndate",
      description: "Attorney or Representative Sign Date",
      inputType: "date",
      value: null,
    },
    {
      name: "attorneyorrepsignature",
      description: "Attorney or Representative Signature",
      inputType: "text",
      value: null,
    },
  ],
}
