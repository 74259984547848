




































































import Vue, { VueConstructor } from "vue"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { attorneyNameChangeForm } from "@/constants/policyChangeForms"
import Form from "@/components/Policies/Forms/Form.vue"
import FormSection from "../Forms/FormSection.vue"
import FormRow from "../Forms/FormRow.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import TextArea from "../../TextArea.vue"
import TextInput from "../../TextInput.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import FormDetail from "../Forms/FormDetail.vue"
import FormContent from "../Forms/FormContent.vue"

export default (Vue as VueConstructor<Vue & typeof FormSubmissionMixin>).extend({
  components: {
    Form,
    FormSection,
    FormRow,
    FormSectionContent,
    TextArea,
    TextInput,
    FormDatePicker,
    FormDetail,
    FormContent,
  },
  mixins: [FormSubmissionMixin],
  data: () => ({
    formToSubmit: attorneyNameChangeForm,
    userSubmissionForm: {
      type: "attorneyNameChange",
      description: "Attorney Name Change",
      items: {
        attorneynamecurrent: null,
        attorneynamenew: null,
        namechangereason: null,
        signature: {
          attorneyorrepsignature: null,
          attorneyorrepsigndate: null,
        },
      },
    },
  }),
})
