import { UserRecordWithClient } from "@/types/UserRecord"

export const currentUserStateStore = {
  state: {
    user: null as null | UserRecordWithClient,
  },

  setCurrentUser(user: UserRecordWithClient) {
    this.state.user = user
  },
}
