











































import Vue from "vue"
import TextInput from "@/components/TextInput.vue"
import { validateSimpleDate } from "@/validators/validateSimpleDate"

const FUTURE_DATE_MAX_YEARS = 6

export default Vue.extend({
  components: { TextInput },
  props: {
    label: {
      type: String,
      default: "Date",
    },
    dateStepper: {
      type: Boolean,
      default: null,
    },
    redLabel: {
      type: Boolean,
      default: null,
    },
    hint: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: null,
    },
    noMaxDate: {
      type: Boolean,
      default: null,
    },
    futureDate: {
      type: Boolean,
      default: null,
    },
    minDateCurrentYear: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    internalActivePicker: "YEAR" as null | string,
    selectedDate: null as string | null,
    dateMenu: false,
    dateValue: null as null | string,
    validateSimpleDate,
    id: null as null | string,
    shouldValidateDate: true,
  }),
  computed: {
    currentDateLocalized(): null | string {
      return this.noMaxDate ? null : this.getMaxDate()
    },
    minDate() {
      return this.minDateCurrentYear ? `${new Date().getFullYear()}-01-01` : "1900-01-01"
    },
  },
  watch: {
    // When a date stepper is used, the underlying text input is validated when the user selects a year, causing the field to enter
    // an error state before the user has finished selecting a date. To prevent this, we have to take the hacky approach of
    // disabling validation on the field when the user begins selecting a date, and re-enabling it when they close the picker
    dateMenu(val, prevValue) {
      if (this.dateStepper && val && !prevValue) {
        this.shouldValidateDate = false
        this.$nextTick(() => this.setActivePicker())
      }
      if (!val && this.required) {
        this.shouldValidateDate = true
      }
    },
  },
  beforeMount() {
    this.id = `form-date-container-${Math.random().toString().slice(2)}`
  },
  mounted() {
    this.shouldValidateDate = this.required
  },
  methods: {
    handleDateSelected() {
      this.selectedDate = this.formatDate(this.dateValue)
      this.$emit("input", this.selectedDate)
    },
    getMaxDate() {
      if (this.futureDate) {
        const fiveYearsInFuture = new Date(`${new Date().getFullYear() + FUTURE_DATE_MAX_YEARS}`)

        return this.getVuetifyDate(fiveYearsInFuture.getTime())
      }

      return this.getVuetifyDate(Date.now())
    },
    getVuetifyDate(date: number) {
      return new Date(date - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10)
    },
    formatDate(date: string | null) {
      if (!date) return ""

      const [year, month, day] = date.split("-")

      if (typeof month === "undefined") {
        return year
      }

      if (typeof day === "undefined") {
        return `${month}/${year}`
      }

      return `${month}/${day}/${year}`
    },

    save(date: string) {
      const menuRef = this.$refs.menu as unknown as { menu: HTMLDivElement; save: (param: string) => boolean }
      this.$nextTick(function setMenu() {
        menuRef.save(date)
      })
    },

    setActivePicker() {
      const refPicker = this.$refs.picker as unknown as { activePicker: string }
      this.$nextTick(function setPick() {
        refPicker.activePicker = "YEAR"
      })
    },
  },
})
