import { Contact } from "@/types/Contact"

export const contactsStore = {
  state: {
    contacts: null as null | Contact[],
  },

  setContacts(contacts: Contact[]) {
    this.state.contacts = contacts
  },
}
