











import Vue from "vue"
import { validateRequired } from "../validators/validateRequired"

export default Vue.extend({
  components: {},
  props: {
    text: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    validateRequired,
  }),
  computed: {
    includesSearch(): boolean {
      if (this.search.trim().length === 0) {
        return false
      }

      return this.text.toUpperCase().includes(this.search.toUpperCase())
    },
    spans(): string[] {
      if (this.search.trim().length === 0) {
        return [this.text]
      }

      const searchIndex = this.text.toUpperCase().indexOf(this.search.toUpperCase())

      return [
        this.text.slice(0, searchIndex),
        this.text.slice(searchIndex, searchIndex + this.search.length),
        this.text.slice(searchIndex + this.search.length),
      ]
    },
  },
})
