import { SuccessMessageResponse } from "@/types/SuccessMessage"
import { makeAuthenticatedRequest } from "@/util/makeAuthenticatedRequest"
import { getMessagesUrl } from "@/util/urls"

export const postPolicyMessage = async (
  clientKey: string,
  policyKey: string,
  message: any
): Promise<SuccessMessageResponse> =>
  makeAuthenticatedRequest(`${getMessagesUrl()}/${clientKey}/${policyKey}`, "POST", message)

export const postClientMessage = async (clientKey: string, message: any): Promise<SuccessMessageResponse> =>
  makeAuthenticatedRequest(`${getMessagesUrl()}/${clientKey}`, "POST", message)
