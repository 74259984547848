


















import Subtitle from "@/components/Subtitle.vue"
import Vue from "vue"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import PolicyBalance from "../PolicyBalance.vue"

export default Vue.extend({
  components: { PolicyBalance, Subtitle },
  props: {
    policy: {
      type: Object,
      default: null,
    },
    clientKey: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    items: [] as any,
  }),
  async mounted() {
    const addAttorneyUrl = await appendFirmsIfAdminViewingClient(
      `/policies/${this.policy.policyKey}/policy-change/add-attorney`,
      this.$route.params.clientKey
    )
    const viewPolicyUrl = await appendFirmsIfAdminViewingClient(
      `/policies/${this.policy.policyKey}`,
      this.$route.params.clientKey
    )
    this.items = [
      { icon: "mdi-file-document-outline", label: "View Your Policy", to: viewPolicyUrl },
      {
        icon: "mdi-pencil-box-outline",
        label: "Make a Policy Change",
        to: addAttorneyUrl,
      },
    ]
  },
})
