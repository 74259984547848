<template>
  <div class="edit-firm-user">
    <UserInformationTemplate
      :is-loading="isLoading"
      title="Edit User"
      :return-btn="`Back to ${firmName}`"
      :selected-user="selectedUser"
      submit-label="Save"
      @back="handleBackButton"
      @submit="handleUpdateUser"
      @error="handleSubmissionError"
    ></UserInformationTemplate>
  </div>
</template>

<script>
import { normalizePhoneNumber } from "@/util/normalizePhoneNumber"
import UserInformationTemplate from "@/components/LMNCAdmin/UserInformationTemplate.vue"
import { getClientNameByClientKey, getUserByUserId, updateClientUserById } from "@/services/clientService"

export default {
  components: { UserInformationTemplate },
  data: () => ({
    isLoading: true,
    selectedUser: null,
    firmName: null,
  }),
  async mounted() {
    this.firmName = await getClientNameByClientKey(this.$route.params.clientKey)
    await this.initializeEditUser()
  },
  methods: {
    handleBackButton() {
      this.$router.back()
    },
    async initializeEditUser() {
      const selectedUserId = this.$route.params.firmUserId
      this.selectedUser = await this.fetchUserInformationById(selectedUserId)
      this.isLoading = false
    },

    async fetchUserInformationById(selectedUserId) {
      const response = await getUserByUserId(selectedUserId)
      return response
    },

    async handleUpdateUser(value) {
      this.isLoading = true

      try {
        const { firstName, lastName, phone, isClientAdmin } = value

        await updateClientUserById(this.$route.params.firmUserId, {
          firstName,
          lastName,
          phone: normalizePhoneNumber(phone),
          isClientAdmin,
        })

        this.$emit("updated-firm-users", { text: `Updated ${value.firstName} ${value.lastName}`, variety: "success" })
        this.handleBackButton()
      } catch (error) {
        this.handleSubmissionError(`${error}`)
      }
      this.isLoading = false
    },

    handleSubmissionError(value) {
      this.isLoading = true
      this.$emit("updated-firm-users", value)
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-firm-user {
  height: 100%;
  min-height: 75rem;
  width: 100%;
}
</style>
