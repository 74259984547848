<template>
  <div class="user-dashboard">
    <div v-if="isLoading" class="loading-page-spinner d-flex justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="80"></v-progress-circular>
    </div>
    <transition v-else name="route" mode="out-in">
      <router-view :is-lmnc-admin="isLmncAdmin" :is-client-admin="isLmncAdmin" :current-user="currentUser" />
    </transition>
    <PolicyChangeConfirmationDialog
      :show-dialog="showMessageSubmittedConfirmation"
      @closed="() => (showMessageSubmittedConfirmation = false)"
    />
  </div>
</template>

<script>
import { policyFormSubmittedStore } from "@/stores/policyFormSubmittedStore"
import PolicyChangeConfirmationDialog from "@/components/Policies/PolicyChangeConfirmationDialog.vue"

export default {
  components: { PolicyChangeConfirmationDialog },
  beforeRouteUpdate(to, from, next) {
    if (policyFormSubmittedStore.state.policyFormSubmitted) {
      this.showMessageSubmittedConfirmation = true
      policyFormSubmittedStore.setPolicyFormSubmitted(false)
    }

    next()
  },
  props: {
    isLmncAdmin: {
      type: Boolean,
      default: null,
    },
    isClientAdmin: {
      type: Boolean,
      default: null,
    },
    isUser: {
      type: Boolean,
      default: null,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isLoading: true,
    showMessageSubmittedConfirmation: false,
  }),
  mounted() {
    this.$nextTick(() => {
      this.roleCheck()
      this.isLoading = false
    })
  },
  methods: {
    roleCheck() {
      if (this.isLmncAdmin) {
        this.$router.push("/firms")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-dashboard {
  height: 100%;
  width: 100%;
}

.loading-page-spinner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75rem;
}
</style>
