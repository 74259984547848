






import Vue from "vue"

export default Vue.extend({
  props: {
    pTop: {
      type: Boolean,
      default: null,
    },
    pRight: {
      type: Boolean,
      default: null,
    },
    pBtm: {
      type: Boolean,
      default: null,
    },
    pLeft: {
      type: Boolean,
      default: null,
    },
    tableLabel: {
      type: Boolean,
      default: null,
    },
    subtitle: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    addedPadding() {
      return `padding: ${this.pTop ? "1.5rem" : "0rem"} ${this.pRight ? "1.5rem" : "0rem"} ${
        this.pBtm ? "1.5rem" : "0rem"
      }  ${this.pLeft ? "0.5rem" : "0rem"}`
    },
    bodyClass() {
      if (this.tabelLabel) {
        return "table-label"
      }
      if (this.subtitle) {
        return "form-subtitle"
      }
      return "form-body"
    },
  },
})
