<template>
  <span class="lmnc-subtitle">
    <slot></slot>
  </span>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.lmnc-subtitle {
  font-weight: bolder;
  color: var(--grey);
  font-size: 1.4rem;
  font-family: "Montserrat", sans-serif;
}
</style>
