


























import { DEFAULT_PAGE_SIZE } from "@/constants/defaultPageSize"
import { getAllQuoteDocumentsByClientKey } from "@/services/quotesServices"
import { currentUserStateStore } from "@/stores/currentUserStore"
import { QuoteDocument } from "@/types/QuoteDocument"
import { DocumentDownloadMixin } from "@/mixins/documentDownload"
import DownloadRow from "@/components/DownloadRow.vue"
import Vue, { VueConstructor } from "vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import LmncTable from "../LmncTable.vue"

export default (Vue as VueConstructor<Vue & typeof DocumentDownloadMixin>).extend({
  components: { LmncTable, DownloadRow },
  mixins: [DocumentDownloadMixin],
  props: {
    quoteKey: {
      type: String,
      required: true,
    },
    quoteVersion: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      quoteDocuments: null as null | QuoteDocument[],
      headers: [
        {
          text: "Quote Key",
          value: "documentKey",
          sortable: "false",
        },
      ],
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalPages: 1,
      totalNumberOfQuotes: 3,
      userStateStore: currentUserStateStore.state,
    }
  },
  computed: {
    isLoading(): boolean {
      return this.quoteDocuments === null
    },
  },
  mounted() {
    this.fetchQuotes()
  },
  methods: {
    async fetchQuotes() {
      await safelyDoWithUserInfo(async user => {
        const response = await getAllQuoteDocumentsByClientKey(
          getClientKeyFromUserOrUrl(this, user),
          this.quoteKey,
          this.quoteVersion
        )

        this.quoteDocuments = response
      })
    },
    handlePageChange(pageNumber: number) {
      this.pageNumber = pageNumber
    },
  },
})
