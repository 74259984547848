












import Vue from "vue"

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: null,
    },
    divider: {
      type: Boolean,
      default: true,
    },
  },
})
