import Vue from "vue"
import { getClientNameByClientKey } from "@/services/clientService"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getAllPoliciesByClientKey } from "@/services/policyService"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"

export const AutofillFirmInfoMixin = (generatePolicyNumber = false, firmNameField = "firmname") =>
  Vue.extend({
    data: () => ({
      autofillingClientName: true,
      autofillingAddress: true,
      autofillingPolicyNumber: true,
      userSubmissionForm: {
        items: {
          firmname: null as string | null,
          policykey: null as string | null,
        } as { [key: string]: any },
      },
    }),
    mounted() {
      this.autofillFirmName()
      this.autofillPolicyKey()
    },
    methods: {
      autofillFirmName() {
        safelyDoWithUserInfo(async user => {
          const clientName = await getClientNameByClientKey(getClientKeyFromUserOrUrl(this, user))
          this.userSubmissionForm.items[firmNameField] = clientName
          this.autofillingClientName = false
        })
      },
      truncatePolicyKey(policyKey: string) {
        return policyKey.slice(0, policyKey.lastIndexOf("-"))
      },
      autofillPolicyKey() {
        if (generatePolicyNumber) {
          safelyDoWithUserInfo(async user => {
            const activePolicies = await getAllPoliciesByClientKey(getClientKeyFromUserOrUrl(this, user))
            const { policyKey } = activePolicies[0]
            this.userSubmissionForm.items.policykey = this.truncatePolicyKey(policyKey)
            this.autofillingPolicyNumber = false
          })
        } else {
          this.userSubmissionForm.items.policykey = this.$route.params.policyKey
          this.autofillingPolicyNumber = false
        }
      },
    },
  })
