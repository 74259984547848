<template>
  <div id="formio" class="container mt-5"></div>
</template>

<script>
import { EventBus } from "@/util/eventBus"

export default {
  name: "ReissueForm",
  props: {
    policyKey: {
      type: String,
      required: true,
    },
    keycloakToken: {
      type: String,
      required: true,
    },
  },
  mounted() {
    window.policyKey = this.policyKey
    window.keycloakToken = this.keycloakToken
    const timestamp = new Date().getTime()
    const script = document.createElement("script")
    script.type = "module"
    script.id = "formioJS"
    script.src = `https://staging-app.lawyersmutualnc.com/index-form.js?v=${timestamp}`
    document.head.appendChild(script)
  },
  onMounted() {
    EventBus.$on("tokenUpdated", updatedToken => {
      window.keycloakToken = updatedToken
    })
  },
  beforeDestroy() {
    EventBus.$off("tokenUpdated")
  },
}
</script>

<!-- <style lang="css" scoped src="https://unpkg.com/formiojs@latest/dist/formio.full.min.css" /> -->

<style scoped>
@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css";
.choices {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  font-size: 16px;
}
.choices:focus {
  outline: 0;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-open {
  overflow: visible;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}
.choices[data-type*="select-one"] {
  cursor: pointer;
}
.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 0;
}
.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.25;
}
.choices[data-type*="select-one"] .choices__button:focus,
.choices[data-type*="select-one"] .choices__button:hover {
  opacity: 1;
}
.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}
.choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*="select-one"]::after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*="select-one"].is-open::after {
  border-color: transparent transparent #333 transparent;
  margin-top: -7.5px;
}
.choices[data-type*="select-one"][dir="rtl"]::after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}
.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}
.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="select-multiple"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover {
  opacity: 1;
}
.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}
.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}
.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}
.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaa;
  border: 1px solid #919191;
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.is-active.choices__list--dropdown,
.is-active.choices__list[aria-expanded] {
  visibility: visible;
}
.is-open .choices__list--dropdown,
.is-open .choices__list[aria-expanded] {
  border-color: #b7b7b7;
}
.is-flipped .choices__list--dropdown,
.is-flipped .choices__list[aria-expanded] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir="rtl"] .choices__list--dropdown .choices__item,
[dir="rtl"] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable,
  .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable::after,
  .choices__list[aria-expanded] .choices__item--selectable::after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable,
  [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable::after,
  [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable::after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  opacity: 0.5;
}
.choices__item {
  cursor: default;
}
.choices__item--selectable {
  cursor: pointer;
}
.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0.5;
}
.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}
.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: 0;
}
.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
.choices__input::-webkit-search-cancel-button,
.choices__input::-webkit-search-decoration,
.choices__input::-webkit-search-results-button,
.choices__input::-webkit-search-results-decoration {
  display: none;
}
.choices__input::-ms-clear,
.choices__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}
.choices__placeholder {
  opacity: 0.5;
}
.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}
dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: #fff;
  color: #000;
  display: block;
}
dialog:not([open]) {
  display: none;
}
dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}
._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em #eee;
  border-radius: 0.1em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right {
  margin-left: 0.3em;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fa.pull-left {
  margin-right: 0.3em;
}
.fa.pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  filter: rotate(180deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  filter: rotate(270deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0,  mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  filter: flipV;
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2,  mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-rotate-90 {
  filter: none;
}
.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
.fa-glass:before {
  content: "\f000";
}
.fa-music:before {
  content: "\f001";
}
.fa-search:before {
  content: "\f002";
}
.fa-envelope-o:before {
  content: "\f003";
}
.fa-heart:before {
  content: "\f004";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-o:before {
  content: "\f006";
}
.fa-user:before {
  content: "\f007";
}
.fa-film:before {
  content: "\f008";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-check:before {
  content: "\f00c";
}
.fa-close:before,
.fa-remove:before,
.fa-times:before {
  content: "\f00d";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-signal:before {
  content: "\f012";
}
.fa-cog:before,
.fa-gear:before {
  content: "\f013";
}
.fa-trash-o:before {
  content: "\f014";
}
.fa-home:before {
  content: "\f015";
}
.fa-file-o:before {
  content: "\f016";
}
.fa-clock-o:before {
  content: "\f017";
}
.fa-road:before {
  content: "\f018";
}
.fa-download:before {
  content: "\f019";
}
.fa-arrow-circle-o-down:before {
  content: "\f01a";
}
.fa-arrow-circle-o-up:before {
  content: "\f01b";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-play-circle-o:before {
  content: "\f01d";
}
.fa-repeat:before,
.fa-rotate-right:before {
  content: "\f01e";
}
.fa-refresh:before {
  content: "\f021";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-lock:before {
  content: "\f023";
}
.fa-flag:before {
  content: "\f024";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-print:before {
  content: "\f02f";
}
.fa-camera:before {
  content: "\f030";
}
.fa-font:before {
  content: "\f031";
}
.fa-bold:before {
  content: "\f032";
}
.fa-italic:before {
  content: "\f033";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-list:before {
  content: "\f03a";
}
.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-video-camera:before {
  content: "\f03d";
}
.fa-image:before,
.fa-photo:before,
.fa-picture-o:before {
  content: "\f03e";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-tint:before {
  content: "\f043";
}
.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}
.fa-share-square-o:before {
  content: "\f045";
}
.fa-check-square-o:before {
  content: "\f046";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-play:before {
  content: "\f04b";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-eject:before {
  content: "\f052";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-times-circle-o:before {
  content: "\f05c";
}
.fa-check-circle-o:before {
  content: "\f05d";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}
.fa-expand:before {
  content: "\f065";
}
.fa-compress:before {
  content: "\f066";
}
.fa-plus:before {
  content: "\f067";
}
.fa-minus:before {
  content: "\f068";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-exclamation-triangle:before,
.fa-warning:before {
  content: "\f071";
}
.fa-plane:before {
  content: "\f072";
}
.fa-calendar:before {
  content: "\f073";
}
.fa-random:before {
  content: "\f074";
}
.fa-comment:before {
  content: "\f075";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-key:before {
  content: "\f084";
}
.fa-cogs:before,
.fa-gears:before {
  content: "\f085";
}
.fa-comments:before {
  content: "\f086";
}
.fa-thumbs-o-up:before {
  content: "\f087";
}
.fa-thumbs-o-down:before {
  content: "\f088";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-heart-o:before {
  content: "\f08a";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-linkedin-square:before {
  content: "\f08c";
}
.fa-thumb-tack:before {
  content: "\f08d";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-upload:before {
  content: "\f093";
}
.fa-lemon-o:before {
  content: "\f094";
}
.fa-phone:before {
  content: "\f095";
}
.fa-square-o:before {
  content: "\f096";
}
.fa-bookmark-o:before {
  content: "\f097";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-hand-o-right:before {
  content: "\f0a4";
}
.fa-hand-o-left:before {
  content: "\f0a5";
}
.fa-hand-o-up:before {
  content: "\f0a6";
}
.fa-hand-o-down:before {
  content: "\f0a7";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}
.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}
.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-floppy-o:before,
.fa-save:before {
  content: "\f0c7";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-bars:before,
.fa-navicon:before,
.fa-reorder:before {
  content: "\f0c9";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-money:before {
  content: "\f0d6";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-sort:before,
.fa-unsorted:before {
  content: "\f0dc";
}
.fa-sort-desc:before,
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-asc:before,
.fa-sort-up:before {
  content: "\f0de";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}
.fa-gavel:before,
.fa-legal:before {
  content: "\f0e3";
}
.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-comment-o:before {
  content: "\f0e5";
}
.fa-comments-o:before {
  content: "\f0e6";
}
.fa-bolt:before,
.fa-flash:before {
  content: "\f0e7";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-clipboard:before,
.fa-paste:before {
  content: "\f0ea";
}
.fa-lightbulb-o:before {
  content: "\f0eb";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-bell-o:before {
  content: "\f0a2";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cutlery:before {
  content: "\f0f5";
}
.fa-file-text-o:before {
  content: "\f0f6";
}
.fa-building-o:before {
  content: "\f0f7";
}
.fa-hospital-o:before {
  content: "\f0f8";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}
.fa-circle-o:before {
  content: "\f10c";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-circle:before {
  content: "\f111";
}
.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-folder-o:before {
  content: "\f114";
}
.fa-folder-open-o:before {
  content: "\f115";
}
.fa-smile-o:before {
  content: "\f118";
}
.fa-frown-o:before {
  content: "\f119";
}
.fa-meh-o:before {
  content: "\f11a";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-keyboard-o:before {
  content: "\f11c";
}
.fa-flag-o:before {
  content: "\f11d";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-code:before {
  content: "\f121";
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}
.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-crop:before {
  content: "\f125";
}
.fa-code-fork:before {
  content: "\f126";
}
.fa-chain-broken:before,
.fa-unlink:before {
  content: "\f127";
}
.fa-question:before {
  content: "\f128";
}
.fa-info:before {
  content: "\f129";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-shield:before {
  content: "\f132";
}
.fa-calendar-o:before {
  content: "\f133";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-minus-square-o:before {
  content: "\f147";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-pencil-square:before {
  content: "\f14b";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-caret-square-o-down:before,
.fa-toggle-down:before {
  content: "\f150";
}
.fa-caret-square-o-up:before,
.fa-toggle-up:before {
  content: "\f151";
}
.fa-caret-square-o-right:before,
.fa-toggle-right:before {
  content: "\f152";
}
.fa-eur:before,
.fa-euro:before {
  content: "\f153";
}
.fa-gbp:before {
  content: "\f154";
}
.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}
.fa-inr:before,
.fa-rupee:before {
  content: "\f156";
}
.fa-cny:before,
.fa-jpy:before,
.fa-rmb:before,
.fa-yen:before {
  content: "\f157";
}
.fa-rouble:before,
.fa-rub:before,
.fa-ruble:before {
  content: "\f158";
}
.fa-krw:before,
.fa-won:before {
  content: "\f159";
}
.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-text:before {
  content: "\f15c";
}
.fa-sort-alpha-asc:before {
  content: "\f15d";
}
.fa-sort-alpha-desc:before {
  content: "\f15e";
}
.fa-sort-amount-asc:before {
  content: "\f160";
}
.fa-sort-amount-desc:before {
  content: "\f161";
}
.fa-sort-numeric-asc:before {
  content: "\f162";
}
.fa-sort-numeric-desc:before {
  content: "\f163";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-youtube-square:before {
  content: "\f166";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-adn:before {
  content: "\f170";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitbucket-square:before {
  content: "\f172";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-apple:before {
  content: "\f179";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-android:before {
  content: "\f17b";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-trello:before {
  content: "\f181";
}
.fa-female:before {
  content: "\f182";
}
.fa-male:before {
  content: "\f183";
}
.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}
.fa-sun-o:before {
  content: "\f185";
}
.fa-moon-o:before {
  content: "\f186";
}
.fa-archive:before {
  content: "\f187";
}
.fa-bug:before {
  content: "\f188";
}
.fa-vk:before {
  content: "\f189";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-arrow-circle-o-right:before {
  content: "\f18e";
}
.fa-arrow-circle-o-left:before {
  content: "\f190";
}
.fa-caret-square-o-left:before,
.fa-toggle-left:before {
  content: "\f191";
}
.fa-dot-circle-o:before {
  content: "\f192";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-try:before,
.fa-turkish-lira:before {
  content: "\f195";
}
.fa-plus-square-o:before {
  content: "\f196";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-slack:before {
  content: "\f198";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-bank:before,
.fa-institution:before,
.fa-university:before {
  content: "\f19c";
}
.fa-graduation-cap:before,
.fa-mortar-board:before {
  content: "\f19d";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-spoon:before {
  content: "\f1b1";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}
.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-file-pdf-o:before {
  content: "\f1c1";
}
.fa-file-word-o:before {
  content: "\f1c2";
}
.fa-file-excel-o:before {
  content: "\f1c3";
}
.fa-file-powerpoint-o:before {
  content: "\f1c4";
}
.fa-file-image-o:before,
.fa-file-photo-o:before,
.fa-file-picture-o:before {
  content: "\f1c5";
}
.fa-file-archive-o:before,
.fa-file-zip-o:before {
  content: "\f1c6";
}
.fa-file-audio-o:before,
.fa-file-sound-o:before {
  content: "\f1c7";
}
.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}
.fa-file-code-o:before {
  content: "\f1c9";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-ring:before,
.fa-life-saver:before,
.fa-support:before {
  content: "\f1cd";
}
.fa-circle-o-notch:before {
  content: "\f1ce";
}
.fa-ra:before,
.fa-rebel:before,
.fa-resistance:before {
  content: "\f1d0";
}
.fa-empire:before,
.fa-ge:before {
  content: "\f1d1";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-hacker-news:before,
.fa-y-combinator-square:before,
.fa-yc-square:before {
  content: "\f1d4";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}
.fa-paper-plane:before,
.fa-send:before {
  content: "\f1d8";
}
.fa-paper-plane-o:before,
.fa-send-o:before {
  content: "\f1d9";
}
.fa-history:before {
  content: "\f1da";
}
.fa-circle-thin:before {
  content: "\f1db";
}
.fa-header:before {
  content: "\f1dc";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-sliders:before {
  content: "\f1de";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-futbol-o:before,
.fa-soccer-ball-o:before {
  content: "\f1e3";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-newspaper-o:before {
  content: "\f1ea";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bell-slash-o:before {
  content: "\f1f7";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-eyedropper:before {
  content: "\f1fb";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-area-chart:before {
  content: "\f1fe";
}
.fa-pie-chart:before {
  content: "\f200";
}
.fa-line-chart:before {
  content: "\f201";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bus:before {
  content: "\f207";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-cc:before {
  content: "\f20a";
}
.fa-ils:before,
.fa-shekel:before,
.fa-sheqel:before {
  content: "\f20b";
}
.fa-meanpath:before {
  content: "\f20c";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-venus:before {
  content: "\f221";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-facebook-official:before {
  content: "\f230";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-server:before {
  content: "\f233";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-bed:before,
.fa-hotel:before {
  content: "\f236";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-train:before {
  content: "\f238";
}
.fa-subway:before {
  content: "\f239";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-y-combinator:before,
.fa-yc:before {
  content: "\f23b";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-battery-4:before,
.fa-battery-full:before,
.fa-battery:before {
  content: "\f240";
}
.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-sticky-note-o:before {
  content: "\f24a";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-hourglass-o:before {
  content: "\f250";
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}
.fa-hand-paper-o:before,
.fa-hand-stop-o:before {
  content: "\f256";
}
.fa-hand-scissors-o:before {
  content: "\f257";
}
.fa-hand-lizard-o:before {
  content: "\f258";
}
.fa-hand-spock-o:before {
  content: "\f259";
}
.fa-hand-pointer-o:before {
  content: "\f25a";
}
.fa-hand-peace-o:before {
  content: "\f25b";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-safari:before {
  content: "\f267";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-television:before,
.fa-tv:before {
  content: "\f26c";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-calendar-plus-o:before {
  content: "\f271";
}
.fa-calendar-minus-o:before {
  content: "\f272";
}
.fa-calendar-times-o:before {
  content: "\f273";
}
.fa-calendar-check-o:before {
  content: "\f274";
}
.fa-industry:before {
  content: "\f275";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-map-o:before {
  content: "\f278";
}
.fa-map:before {
  content: "\f279";
}
.fa-commenting:before {
  content: "\f27a";
}
.fa-commenting-o:before {
  content: "\f27b";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-vimeo:before {
  content: "\f27d";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-edge:before {
  content: "\f282";
}
.fa-credit-card-alt:before {
  content: "\f283";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-modx:before {
  content: "\f285";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-usb:before {
  content: "\f287";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-pause-circle-o:before {
  content: "\f28c";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stop-circle-o:before {
  content: "\f28e";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-percent:before {
  content: "\f295";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-envira:before {
  content: "\f299";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-wheelchair-alt:before {
  content: "\f29b";
}
.fa-question-circle-o:before {
  content: "\f29c";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-volume-control-phone:before {
  content: "\f2a0";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-american-sign-language-interpreting:before,
.fa-asl-interpreting:before {
  content: "\f2a3";
}
.fa-deaf:before,
.fa-deafness:before,
.fa-hard-of-hearing:before {
  content: "\f2a4";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-sign-language:before,
.fa-signing:before {
  content: "\f2a7";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3";
}
.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-handshake-o:before {
  content: "\f2b5";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-o:before {
  content: "\f2b7";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-book-o:before {
  content: "\f2ba";
}
.fa-address-card:before,
.fa-vcard:before {
  content: "\f2bb";
}
.fa-address-card-o:before,
.fa-vcard-o:before {
  content: "\f2bc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-circle-o:before {
  content: "\f2be";
}
.fa-user-o:before {
  content: "\f2c0";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-drivers-license:before,
.fa-id-card:before {
  content: "\f2c2";
}
.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\f2c3";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-thermometer-4:before,
.fa-thermometer-full:before,
.fa-thermometer:before {
  content: "\f2c7";
}
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-bath:before,
.fa-bathtub:before,
.fa-s15:before {
  content: "\f2cd";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\f2d3";
}
.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\f2d4";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-eercast:before {
  content: "\f2da";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-snowflake-o:before {
  content: "\f2dc";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-meetup:before {
  content: "\f2e0";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.formio-loader {
  position: relative;
  min-height: 60px;
}
.loader-wrapper {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 120px;
  background-color: rgba(0, 0, 0, 0);
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 10000;
  display: inline-block;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.formio-form {
  position: relative;
  min-height: 80px;
}
.formio-error-wrapper,
.formio-warning-wrapper {
  padding: 1em;
}
.formio-error-wrapper {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.formio-error-wrapper .formio-errors .error {
  color: #c20000;
}
.formio-error-wrapper .field-required:after {
  color: #c20000;
}
.formio-warning-wrapper {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.formio-disabled-input .form-control.flatpickr-input {
  background-color: #eee;
}
.builder-component.has-error .invalid-feedback,
.formio-component-modal-wrapper.has-error .invalid-feedback,
.formio-component-modal-wrapper.has-message .invalid-feedback,
.formio-component.alert-danger .invalid-feedback,
.formio-component.has-error .invalid-feedback,
.formio-component.has-message .invalid-feedback {
  display: block;
  color: inherit;
  margin-top: 4px;
}
.formio-errors .error {
  color: #dc3545;
}
.formio-errors .warning {
  color: #856404;
}
.formio-errors .info {
  color: #004085;
}
.formio-form-group {
  margin-bottom: 1rem;
}
.formio-wysiwyg-editor {
  min-height: 200px;
  background-color: #fff;
}
.has-feedback .form-control {
  padding-right: 10px;
}
.has-feedback .form-control[type="hidden"] {
  padding-right: 0;
}
.has-error.bg-danger {
  padding: 4px;
}
.ql-source:after {
  content: "[source]";
  white-space: nowrap;
}
.quill-source-code {
  width: 100%;
  margin: 0;
  background: #1d1d1d;
  box-sizing: border-box;
  color: #ccc;
  font-size: 15px;
  outline: 0;
  padding: 20px;
  line-height: 24px;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  display: none;
}
.formio-component-tags tags {
  background-color: #fff;
}
.field-required:after,
.tab-error::after {
  content: " *";
  color: #eb0000;
}
.field-required:after {
  position: relative;
  z-index: 10;
}
.glyphicon-spin {
  -webkit-animation: formio-spin 1s infinite linear;
  -moz-animation: formio-spin 1s infinite linear;
  -o-animation: formio-spin 1s infinite linear;
  animation: formio-spin 1s infinite linear;
}
@-moz-keyframes formio-spin {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes formio-spin {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes formio-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.button-icon-right {
  margin-left: 5px;
}
.formio-component-submit .submit-success::after {
  content: "✓";
  position: relative;
  right: -4px;
  top: 1px;
  line-height: 1;
}
.formio-component-submit .submit-fail::after {
  content: "✗";
  position: relative;
  right: -4px;
  top: 1px;
  line-height: 1;
}
.card-vertical {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.card-vertical .card-body,
.tab,
.tab-content {
  flex-grow: 2;
}
.nav-tabs-vertical {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  padding-left: 5px;
  margin-right: 10px;
  border-bottom: 0;
}
.card-vertical > .card-body,
.card-vertical > .tab,
.card-vertical > .tab-content {
  flex-basis: 85%;
}
.card-vertical ul > li > .nav-link-vertical {
  border-right-color: transparent;
  border-radius: 4px 0 0 4px;
  margin-right: 0;
}
.card-vertical ul > li > .nav-link-vertical.active {
  border-bottom-color: #ddd;
  border-right-color: transparent;
}
.card-vertical ul > li > .nav-link-vertical.active:hover {
  border-right-color: transparent;
}
.nav-tabs-vertical > li {
  margin: 0 -1px 0 0;
}
.formio-component-submit .submit-fail[disabled] {
  opacity: 1;
}
.form-control.flatpickr-input {
  background-color: #fff;
}
.input-group .flatpickr-wrapper {
  flex-grow: 1;
}
.flatpickr-calendar .flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-calendar .flatpickr-current-month input.cur-year:focus,
.flatpickr-calendar .flatpickr-days:focus {
  outline: auto;
}
td > .form-group,
td > .formio-form-group {
  margin-bottom: 0;
}
.signature-pad-body {
  overflow: hidden;
  position: relative;
}
.signature-pad-body .form-control-feedback {
  position: absolute;
  font-size: 0.8rem;
  top: 1px;
  right: 3px;
}
.signature-pad-canvas {
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
  border: 1px solid #f4f4f4;
}
.btn.signature-pad-refresh {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  padding: 3px;
  line-height: 0;
}
[dir="rtl"] .btn.signature-pad-refresh {
  left: unset;
  right: 0;
}
.formio-component-multiple .choices__input {
  width: 100%;
}
.formio-component-multiple .is-invalid {
  border-color: #f04124;
}
.formio-component-multiple :not(.is-invalid) {
  border-color: #ccc;
}
.choices__list--dropdown .choices__item--selectable {
  padding-right: 0;
}
.signature-pad-refresh img {
  height: 1.2em;
}
.signature-pad-footer {
  text-align: center;
  color: #c3c3c3;
}
.is-active.choices__list--dropdown {
  z-index: 100;
}
.choices__list--multiple .choices__item {
  border-radius: 0;
  padding: 2px 8px;
  line-height: 1em;
  margin-bottom: 6px;
}
.choices__list--single {
  padding: 0;
}
.choices__item.choices__item--selectable {
  white-space: nowrap;
  overflow: hidden;
  padding-right: 25px;
  text-overflow: ellipsis;
}
.choices__input {
  padding: 2px;
}
.choices[dir="rtl"] > * {
  text-align: right;
}
.choices[dir="rtl"] .choices__list--multiple .choices__item[data-deletable] {
  padding-left: 5px;
  float: right;
}
.choices[dir="rtl"] .choices__list--multiple .choices__item[data-deletable] .choices__button {
  float: left;
  margin: 0 8px 0 -4px;
  padding-left: unset;
  padding-right: 16px;
  border-left: unset;
  border-right: 1px solid #008fa1;
  overflow: hidden;
}
@-moz-document url-prefix() {
  .choices__button {
    float: right;
  }
}
.formio-component-file .fileSelector {
  position: relative;
  padding: 15px;
  border: 2px dashed #ddd;
  text-align: center;
}
.formio-component-file .fileSelector .loader-wrapper {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.formio-component-file .fileSelector .loader-wrapper .loader {
  height: 45px;
  width: 45px;
  margin-top: -23px;
  margin-left: -23px;
}
.formio-component-file .fileSelector a {
  text-decoration: underline;
}
.formio-component-file .fileSelector.fileDragOver {
  border-color: #127abe;
}
.formio-component-file .fileSelector .fa,
.formio-component-file .fileSelector .glyphicon {
  font-size: 20px;
  margin-right: 5px;
}
[dir="rtl"] .formio-component-file .fileSelector .fa,
[dir="rtl"] .formio-component-file .fileSelector .glyphicon {
  margin-right: unset;
  margin-left: 5px;
}
.formio-component-file .fileSelector .browse {
  cursor: pointer;
}
@-webkit-keyframes formio-dialog-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes formio-dialog-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes formio-dialog-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes formio-dialog-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.formio-dialog {
  box-sizing: border-box;
  font-size: 0.8em;
  color: #666;
}
.formio-dialog.formio-modaledit-dialog {
  font-size: inherit;
}
.formio-dialog *,
.formio-dialog :after,
.formio-dialog :before {
  box-sizing: inherit;
}
.formio-dialog {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  animation: formio-dialog-fadein 0.5s;
}
.formio-dialog.formio-dialog-disabled-animation,
.formio-dialog.formio-dialog-disabled-animation .formio-dialog-content,
.formio-dialog.formio-dialog-disabled-animation .formio-dialog-overlay {
  -webkit-animation: none !important;
  animation: none !important;
}
.formio-dialog-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: formio-dialog-fadein 0.5s;
  animation: formio-dialog-fadein 0.5s;
  margin-right: 15px;
  background: 0 0;
}
.formio-dialog-no-overlay {
  pointer-events: none;
}
.formio-dialog.formio-dialog-closing .formio-dialog-overlay {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-animation: formio-dialog-fadeout 0.5s;
  animation: formio-dialog-fadeout 0.5s;
}
.formio-dialog-content {
  background: #fff;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-animation: formio-dialog-fadein 0.5s;
  animation: formio-dialog-fadein 0.5s;
  pointer-events: all;
  overflow: auto;
}
.formio-component-modal-wrapper-select .formio-dialog-content {
  overflow: initial;
}
.formio-dialog.formio-dialog-closing .formio-dialog-content {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-animation: formio-dialog-fadeout 0.5s;
  animation: formio-dialog-fadeout 0.5s;
}
.formio-dialog-close:before {
  font-family: Helvetica, Arial, sans-serif;
  content: "×";
  cursor: pointer;
}
body.formio-dialog-open,
html.formio-dialog-open {
  overflow: hidden;
}
.formio-dialog .tab-content {
  padding-top: 12px;
}
.formio-dialog-close {
  z-index: 1000;
}
@-webkit-keyframes formio-dialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes formio-dialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes formio-dialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes formio-dialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
.formio-dialog.formio-dialog-theme-default {
  padding-bottom: 160px;
  padding-top: 160px;
}
.formio-dialog.formio-dialog-theme-default .component-edit-container {
  padding: 0.5em;
}
.formio-dialog.formio-dialog-theme-default.formio-dialog-closing .formio-dialog-content {
  -webkit-animation: formio-dialog-flyout 0.5s;
  animation: formio-dialog-flyout 0.5s;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
  -webkit-animation: formio-dialog-flyin 0.5s;
  animation: formio-dialog-flyin 0.5s;
  background: #f0f0f0;
  border-radius: 5px;
  font-family: Helvetica, sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 0 auto;
  max-width: 100%;
  padding: 1em;
  position: relative;
  width: 80%;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-close {
  border: none;
  background: 0 0;
  cursor: pointer;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 100;
}
.formio-clickable {
  cursor: pointer;
}
.component-settings .nav > li > a {
  padding: 8px 10px;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-close:before {
  display: block;
  padding: 3px;
  background: 0 0;
  color: #8a8a8a;
  content: "×";
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-close:active:before,
.formio-dialog.formio-dialog-theme-default .formio-dialog-close:hover:before {
  color: #777;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-message {
  margin-bottom: 0.5em;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-input {
  margin-bottom: 1em;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="email"],
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="password"],
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="text"],
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="url"],
.formio-dialog.formio-dialog-theme-default .formio-dialog-input textarea {
  background: #fff;
  border: 0;
  border-radius: 3px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 0 0.25em;
  min-height: 2.5em;
  padding: 0.25em 0.67em;
  width: 100%;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="email"]:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="password"]:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="text"]:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input input[type="url"]:focus,
.formio-dialog.formio-dialog-theme-default .formio-dialog-input textarea:focus {
  box-shadow: inset 0 0 0 2px #8dbdf1;
  outline: 0;
}
.formio-dialog-buttons {
  display: flex;
  justify-content: flex-end;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-buttons {
  zoom: 1;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-buttons:after {
  content: "";
  display: table;
  clear: both;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-button {
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  float: right;
  font-family: inherit;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  line-height: 1em;
  margin: 0 0 0 0.5em;
  padding: 0.75em 2em;
  text-transform: uppercase;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-button:focus {
  -webkit-animation: formio-dialog-pulse 1.1s infinite;
  animation: formio-dialog-pulse 1.1s infinite;
  outline: 0;
}
@media (max-width: 568px) {
  .formio-dialog.formio-dialog-theme-default.formio-dialog-button:focus {
    -webkit-animation: none;
    animation: none;
  }
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-button.formio-dialog-button-primary {
  background: #3288e6;
  color: #fff;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-button.formio-dialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}
.formio-dialog-content .panel {
  margin: 0;
}
.formio-dialog-content [ref="dialogHeader"] {
  padding-right: 15px;
}
.formio-placeholder {
  position: absolute;
  color: #999;
}
.formio-dialog .formio-dialog-close {
  cursor: pointer;
}
.formio-iframe {
  border: none;
  width: 100%;
  height: 1000px;
}
.inline-form-button {
  margin-right: 10px;
}
.tooltip {
  opacity: 1;
}
.tooltip[x-placement="right"] .tooltip-arrow {
  border-right: 5px solid #000;
}
.tooltip[x-placement="right"] .tooltip-inner {
  margin-left: 8px;
}
.control-label--bottom {
  margin-bottom: 0;
  margin-top: 5px;
}
.formio-component-label-hidden {
  position: relative;
}
.formio-hidden {
  margin: 0;
}
.formio-removed {
  display: none;
}
.control-label--hidden {
  position: absolute;
  top: 6px;
  right: 5px;
}
.formio-component-datetime .control-label--hidden.field-required {
  right: 45px;
  z-index: 3;
}
.formio-component-selectboxes .control-label--hidden.field-required,
.formio-component-survey .control-label--hidden.field-required {
  top: 0;
}
.formio-component-resource .control-label--hidden.field-required,
.formio-component-select .control-label--hidden.field-required {
  right: 40px;
  z-index: 2;
}
.formio-component-radio .control-label--hidden.field-required:after,
.formio-component-selectboxes .control-label--hidden.field-required:after {
  display: none;
}
.formio-component-radio.formio-component-label-hidden.required .form-check-label:before,
.formio-component-selectboxes.formio-component-label-hidden.required .form-check-label:before {
  position: relative;
  content: "* ";
  color: #eb0000;
}
.formio-component-radio.formio-component-label-hidden.required
  .form-check
  .label-position-right.form-check-label:before,
.formio-component-selectboxes.formio-component-label-hidden.required
  .form-check
  .label-position-right.form-check-label:before {
  right: 20px;
}
.formio-component-datasource,
.formio-component-hidden:not(.formio-component-checkbox) {
  margin-bottom: 0;
}
.checkbox-inline label,
.radio-inline label {
  font-weight: 400;
  cursor: pointer;
}
.editgrid-listgroup {
  margin-bottom: 10px;
  overflow-wrap: break-word;
}
.tree-listgroup {
  flex-direction: row;
}
.formio-component-submit button[disabled] + .has-error {
  display: block;
}
.formio-choices.form-group,
.formio-choices.formio-form-group {
  margin-bottom: 0;
}
.formio-choices[data-type="select-multiple"] .form-control {
  height: auto;
}
.form-control.formio-multiple-mask-select {
  width: 15%;
  z-index: 4;
}
.form-control.formio-multiple-mask-input {
  width: 85%;
}
.input-group.formio-multiple-mask-container {
  width: 100%;
}
.formio-component .table {
  margin-bottom: 0;
  word-break: break-word;
}
.formio-component-htmlelement {
  word-wrap: break-word;
}
.formio-component-htmlelement ol,
.formio-component-htmlelement ul {
  margin-left: 10px;
}
.editgrid-table-container {
  margin-bottom: 10px;
  max-width: calc(100vw - 140px);
}
.editgrid-table-container .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.editgrid-table-column {
  border: none;
}
.editgrid-table-head {
  border: 1px solid #ddd;
}
.editgrid-table-body {
  border: 1px solid #ddd;
  border-top: 0;
}
.formio-hide-label-panel-tooltip {
  margin-top: -10px;
  margin-left: -10px;
}
.is-disabled .choices__list--multiple .choices__item {
  padding: 5px 10px;
}
.is-disabled .choices__list--multiple .choices__item .choices__button {
  display: none;
}
.formio-collapse-icon {
  cursor: pointer;
  margin-right: 4px;
}
[dir="rtl"] .formio-collapse-icon {
  margin-right: unset;
  margin-left: 4px;
}
.formio-component-dateTime .form-control[type="datetime-local"] ~ .input-group-addon,
.formio-component-datetime .form-control[type="datetime-local"] ~ .input-group-addon {
  width: auto;
}
.formio-component-datagrid .formio-datagrid-remove {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s linear, visibility 0s 0.2s;
}
.formio-component-datagrid {
  overflow-x: auto;
}
.formio-component-datagrid .datagrid-table,
.formio-component-datagrid .datagrid-table td,
.formio-component-datagrid .datagrid-table th {
  border: 3px solid #ddd !important;
  padding: 10px;
}
.formio-component-datagrid .datagrid-table > tbody > tr > td:last-child {
  position: relative;
}
.formio-component-datagrid .datagrid-table > tbody > tr:hover > td:last-child .formio-datagrid-remove {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s linear;
}
.datagrid-table > tbody > tr > td {
  word-break: auto-phrase;
}
.formio-component-modaledit .formio-modaledit-view-container {
  position: relative;
  border: 1px solid #ddd;
  min-height: 34px;
  padding: 6px 12px;
  cursor: text;
}
td .formio-component-modaledit .formio-modaledit-view-container {
  padding: 0;
  border-style: none;
}
.formio-component-modaledit .formio-modaledit-edit {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s linear, visibility 0s 0.2s;
}
.formio-component-modaledit .formio-modaledit-view-container:hover .formio-modaledit-edit {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s linear;
}
.formio-modaledit-dialog .formio-modaledit-close {
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 0;
}
.reset-margins a,
.reset-margins abbr,
.reset-margins acronym,
.reset-margins address,
.reset-margins applet,
.reset-margins article,
.reset-margins aside,
.reset-margins audio,
.reset-margins b,
.reset-margins big,
.reset-margins blockquote,
.reset-margins body,
.reset-margins canvas,
.reset-margins caption,
.reset-margins center,
.reset-margins cite,
.reset-margins code,
.reset-margins dd,
.reset-margins del,
.reset-margins details,
.reset-margins dfn,
.reset-margins div,
.reset-margins dl,
.reset-margins dt,
.reset-margins em,
.reset-margins embed,
.reset-margins fieldset,
.reset-margins figcaption,
.reset-margins figure,
.reset-margins footer,
.reset-margins form,
.reset-margins h1,
.reset-margins h2,
.reset-margins h3,
.reset-margins h4,
.reset-margins h5,
.reset-margins h6,
.reset-margins header,
.reset-margins hgroup,
.reset-margins html,
.reset-margins i,
.reset-margins iframe,
.reset-margins img,
.reset-margins ins,
.reset-margins kbd,
.reset-margins label,
.reset-margins legend,
.reset-margins li,
.reset-margins mark,
.reset-margins menu,
.reset-margins nav,
.reset-margins object,
.reset-margins ol,
.reset-margins output,
.reset-margins p,
.reset-margins pre,
.reset-margins q,
.reset-margins ruby,
.reset-margins s,
.reset-margins samp,
.reset-margins section,
.reset-margins small,
.reset-margins span,
.reset-margins strike,
.reset-margins strong,
.reset-margins sub,
.reset-margins summary,
.reset-margins sup,
.reset-margins table,
.reset-margins tbody,
.reset-margins td,
.reset-margins tfoot,
.reset-margins th,
.reset-margins thead,
.reset-margins time,
.reset-margins tr,
.reset-margins tt,
.reset-margins u,
.reset-margins ul,
.reset-margins var,
.reset-margins video {
  margin: 0;
}
.ck-body .ck.ck-balloon-panel {
  z-index: 101000;
}
.formio-component-select select[disabled="disabled"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.formio-component-select .choices.is-disabled[data-type*="select-one"]:after,
.formio-component-select div[disabled="disabled"] button {
  display: none;
}
.datagrid-group-label.collapsed > td {
  display: none;
}
.datagrid-group-header.clickable {
  cursor: pointer;
}
.datagrid-group-header.clickable .datagrid-group-label:before {
  display: inline-block;
  vertical-align: middle;
  content: "▾";
  margin: 0 5px;
}
.datagrid-group-header.clickable.collapsed .datagrid-group-label:before {
  content: "▸";
}
.formio-component.alert-danger .help-block,
.formio-component.alert-warning .help-block {
  color: inherit;
}
.tree__level_even {
  background-color: #f6f6f6;
}
.tree__node-content {
  margin-bottom: 10px;
  overflow-wrap: break-word;
}
.tree__node-children {
  margin: 0;
}
.formio-select-autocomplete-input {
  opacity: 0;
  position: relative;
  z-index: -1;
  display: block;
  height: 0;
  border: none;
}
.has-error > .help-block {
  margin-top: 5px;
  margin-bottom: 10px;
}
.no-top-border-table > .table > tbody > tr:first-child > td {
  border-top: none;
}
.table > tbody > tr > td.cell-align-left {
  text-align: left;
}
.table > tbody > tr > td.cell-align-center {
  text-align: center;
}
.table > tbody > tr > td.cell-align-center > div {
  margin-left: auto;
  margin-right: auto;
}
.table > tbody > tr > td.cell-align-right {
  text-align: right;
}
.table > tbody > tr > td.cell-align-right > div {
  margin-left: auto;
}
.table-responsive[ref="component"] {
  overflow-x: visible;
}
.formio-component-textarea .alert .ck-editor__editable {
  color: inherit;
}
.formio-component-textarea .ck.ck-editor__editable .image .ck-progress-bar {
  height: 4px;
}
.formio-component-textarea .ck.ck-editor ol,
.formio-component-textarea .ck.ck-editor ul {
  margin-left: 10px;
}
div[data-oembed-url] {
  width: 100%;
}
.checkbox label.label-position-bottom,
.checkbox label.label-position-left,
.checkbox label.label-position-top,
.radio label.label-position-bottom,
.radio label.label-position-left,
.radio label.label-position-top {
  padding-left: 0;
}
.checkbox label.label-position-bottom span,
.checkbox label.label-position-top span,
.radio label.label-position-bottom span,
.radio label.label-position-top span {
  display: block;
}
.checkbox label.label-position-bottom input[type="checkbox"],
.checkbox label.label-position-top input[type="checkbox"],
.radio label.label-position-bottom input[type="radio"],
.radio label.label-position-top input[type="radio"] {
  position: relative;
  margin-left: 0;
}
.checkbox label.label-position-top input[type="checkbox"],
.radio label.label-position-top input[type="radio"] {
  margin-top: 4px;
}
.checkbox label.label-position-bottom input[type="checkbox"],
.radio label.label-position-bottom input[type="radio"] {
  margin-bottom: 8px;
}
.radio label.label-position-left input[type="radio"] {
  margin-left: 10px;
}
.checkbox label.label-position-left input[type="checkbox"] {
  margin-left: 4px;
  position: relative;
}
.open-modal-button {
  width: 100%;
  text-align: left;
  white-space: normal;
  height: auto;
}
.formio-component-modal-wrapper-signature .open-modal-button {
  text-align: center;
  height: 100%;
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}
.formio-component-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}
.formio-component-content .image > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}
.formio-component-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0deg, 0%, 20%);
  background-color: hsl(0deg, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
.formio-component-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
.formio-component-content .image.image_resized img {
  width: 100%;
}
.formio-component-content .image.image_resized > figcaption {
  display: block;
}
.formio-component-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}
.formio-component-content .image-style-align-center:not(.image_resized),
.formio-component-content .image-style-align-left:not(.image_resized),
.formio-component-content .image-style-align-right:not(.image_resized),
.formio-component-content .image-style-side:not(.image_resized) {
  max-width: 50%;
}
.formio-component-content .image-style-align-center:not(.image_resized),
.formio-component-content .image-style-align-left:not(.image_resized),
.formio-component-content .image-style-align-right:not(.image_resized),
.formio-component-content .image-style-side:not(.image_resized) {
  max-width: 50%;
}
.formio-component-content .image-style-align-center:not(.image_resized),
.formio-component-content .image-style-align-left:not(.image_resized),
.formio-component-content .image-style-align-right:not(.image_resized),
.formio-component-content .image-style-side:not(.image_resized) {
  max-width: 50%;
}
.formio-component-content .image-style-align-center:not(.image_resized),
.formio-component-content .image-style-align-left:not(.image_resized),
.formio-component-content .image-style-align-right:not(.image_resized),
.formio-component-content .image-style-side:not(.image_resized) {
  max-width: 50%;
}
.formio-component-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.formio-component-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
.formio-component-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
.formio-component-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.formio-component-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0deg, 0%, 80%);
}
.formio-component-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0deg, 0%, 80%);
}
.formio-component-content .text-tiny {
  font-size: 0.7em;
}
.formio-component-content .text-small {
  font-size: 0.85em;
}
.formio-component-content .text-big {
  font-size: 1.4em;
}
.formio-component-content .text-huge {
  font-size: 1.8em;
}
.formio-component-content ol {
  padding-inline-start: 40px;
}
.formio-component-address.formio-component-label-hidden > label.field-required {
  z-index: 1;
}
.formio-component-address.formio-component-label-hidden
  > label.field-required
  ~ .address-autocomplete-container
  .address-autocomplete-remove-value-icon {
  right: 20px;
}
.address-autocomplete-container {
  position: relative;
}
.address-autocomplete-container .address-autocomplete-remove-value-icon {
  cursor: pointer;
  position: absolute;
  margin-top: -9px;
  right: 10px;
  top: 50%;
}
.address-autocomplete-container .address-autocomplete-remove-value-icon--hidden {
  display: none;
}
.autocomplete {
  background: #fff;
  font: 14px/22px "-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid rgba(50, 50, 50, 0.6);
  z-index: 11000;
}
.autocomplete > div {
  cursor: pointer;
  padding: 6px 10px;
}
.autocomplete > div.selected,
.autocomplete > div:hover:not(.group) {
  background: #1e90ff;
  color: #fff;
}
.field-wrapper {
  display: flex;
}
.field-wrapper--reverse {
  flex-direction: row-reverse;
}
.field-wrapper .field-label--right {
  text-align: right;
}
.formio-component-modal-wrapper {
  margin-bottom: 10px;
}
.formio-component-modal-wrapper .open-modal-button {
  height: auto;
}
.formio-component-modal-wrapper .component-rendering-hidden {
  visibility: hidden;
}
.formio-component-textarea div.formio-editor-read-only-content[ref="input"] {
  white-space: pre-wrap;
}
.formio-editor-read-only-content img {
  max-width: 100%;
}
.formio-editor-read-only-content li[data-list="bullet"] {
  list-style-type: none;
}
.formio-editor-read-only-content li[data-list="bullet"] .ql-ui {
  padding-right: 0.5rem;
}
.formio-editor-read-only-content li[data-list="bullet"] .ql-ui::before {
  content: "•";
}
.formio-editor-read-only-content li[data-list="ordered"] {
  list-style-type: none;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.formio-editor-read-only-content li[data-list="ordered"] .ql-ui {
  padding-right: 0.5rem;
}
.formio-editor-read-only-content li[data-list="ordered"] .ql-ui::before {
  content: counter(list-0, decimal) ". ";
}
.formio-editor-read-only-content figure.table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double #b3b3b3;
  table-layout: fixed;
}
.formio-editor-read-only-content figure.table table td,
.formio-editor-read-only-content figure.table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid #bfbfbf;
}
.formio-component-password .pull-right:not(:last-child),
.formio-component-textarea .pull-right:not(:last-child),
.formio-component-textfield .pull-right:not(:last-child) {
  padding-left: 12px;
}
.formio-form > div > nav > ul.pagination {
  flex-flow: wrap row;
  justify-content: flex-start;
}
.formio-form > div > nav > ul.pagination .page-link {
  cursor: pointer;
  color: #1c74d9;
}
.formio-form > div > nav > ul.pagination .page-item.active .page-link {
  color: #fff;
  background-color: #1c74d9;
  border-color: #1c74d9;
}
.classic-pagination {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 15px 10px 15px;
  line-height: 1em;
}
.classic-pagination-page {
  padding: 0;
  position: relative;
}
.classic-pagination-title {
  color: #595959;
  font-size: 16px;
  margin-bottom: 5px;
}
.classic-pagination-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: #fbe8aa;
  top: 40px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}
.classic-pagination-dot::after {
  content: " ";
  width: 14px;
  height: 14px;
  background: #fbbd19;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.classic-pagination .progress,
.classic-pagination-progress {
  position: relative;
  border-radius: 0;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
  border: none;
  padding: 0;
  background-color: #f6f6f6;
}
.classic-pagination .progress-bar,
.classic-pagination-progress-bar {
  width: 0;
  height: 10px;
  box-shadow: none;
  background: #fbe8aa;
}
.classic-pagination-page.complete .classic-pagination-progress-bar,
.classic-pagination-page.complete .progress-bar {
  width: 100%;
}
.classic-pagination-page.active .classic-pagination-progress-bar,
.classic-pagination-page.active .progress-bar {
  width: 50%;
}
.classic-pagination-page.disabled .classic-pagination-dot {
  background-color: #f5f5f5;
}
.classic-pagination-page.disabled .classic-pagination-dot::after {
  opacity: 0;
}
.classic-pagination-page:first-child .classic-pagination-progress,
.classic-pagination-page:first-child .progress {
  left: 50%;
  width: 50%;
}
.classic-pagination-page:first-child.active .classic-pagination-progress-bar,
.classic-pagination-page:first-child.active .progress-bar {
  width: 0%;
}
.classic-pagination-page:last-child .classic-pagination-progress,
.classic-pagination-page:last-child .progress {
  width: 50%;
}
.classic-pagination-page:last-child.active .classic-pagination-progress-bar,
.classic-pagination-page:last-child.active .progress-bar {
  width: 100%;
}
.pac-container {
  z-index: 11000;
}
[ref="buttonMessageContainer"].has-error {
  cursor: pointer;
}
[ref="passwordStrengthIndicator"] {
  display: inline;
}
.formio-security-indicator {
  display: flex;
  height: 5px;
}
.formio-security-indicator [class^="security-"] {
  width: 100%;
  height: 100%;
}
.formio-security-indicator .security-low {
  background-color: #c51e00;
}
.formio-security-indicator .security-medium {
  background-color: #ebb400;
}
.formio-security-indicator .security-high {
  background-color: #bddf00;
}
.formio-security-indicator .security-very-high {
  background-color: #009118;
}
.formio-component-textarea .formio-editor-read-only-content .text-big {
  font-size: 1.4em;
}
.formio-component-textarea .formio-editor-read-only-content .text-huge {
  font-size: 1.8em;
}
.formio-component-textarea .formio-editor-read-only-content .text-small {
  font-size: 0.85em;
}
.formio-component-textarea .formio-editor-read-only-content .text-tiny {
  font-size: 0.7em;
}
.formio-component [ref="valueMaskInput"] {
  display: none;
}
.formio-wizard-nav-container {
  display: flex;
}
.formio-wizard-nav-container li {
  margin-right: 0.5rem;
}
@media not all and (min-width: 30em) {
  .formio-wizard-nav-container {
    flex-direction: column;
  }
  .formio-wizard-nav-container li {
    margin-right: 0;
  }
  .formio-wizard-nav-container li .btn {
    width: 100%;
    margin-bottom: 0.25rem;
  }
}
.formio-tooltip__trigger {
  cursor: pointer;
}
.formio-tooltip__body {
  background-color: #1b1b1b;
  border-radius: 0.25rem;
  bottom: 0;
  color: #f0f0f0;
  display: none;
  font-size: 1rem;
  padding: 0.5rem;
  position: absolute;
  left: 0;
  transform: translateX(-50%);
  width: auto;
  white-space: pre;
  z-index: 1000;
}
.formio-tooltip__body.formio-tooltip--is-set {
  display: block;
}
.formio-tooltip__body--whitespace {
  white-space: normal;
  width: 250px;
}
.formio-tooltip__body--right {
  top: auto;
  transform: translateX(0);
}
.formio-tooltip__body--left {
  top: auto;
  left: 0;
  right: auto;
  transform: translateX(0);
}
.formio-tooltip__body--bottom {
  bottom: auto;
  top: 0;
}
.formio-tooltip__wrapper {
  position: relative;
}
.formio-tooltip__wrapper > span {
  font-weight: 400;
}
.ace_editor,
.ace_editor div,
.ace_editor span {
  font-family: Monaco, Menlo, "Ubuntu Mono", "Droid Sans Mono", Consolas, monospace !important;
}
span[role="link"] {
  text-decoration: underline;
  cursor: pointer;
}
.hidden {
  display: none !important;
}
.formbuilder {
  position: relative;
}
.drag-container {
  padding: 10px;
  border: dotted 2px #e8e8e8;
}
.drag-container:hover {
  cursor: move;
  border: dotted 2px #ccc;
}
.drag-container.formio-builder-form,
.drag-container.formio-builder-form:hover,
.panel-body > .drag-container.formio-builder-components,
.panel-body > .drag-container.formio-builder-components:hover,
.tab-pane > .drag-container.formio-builder-components,
.tab-pane > .drag-container.formio-builder-components:hover {
  padding: 0 0 1rem 0;
  border: none;
}
.component-btn-group {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 0;
  z-index: 1000;
  margin-top: -2px;
}
.builder-component {
  position: relative;
  min-height: 15px;
  margin-bottom: 15px;
}
.builder-component .formio-component-htmlelement {
  border: dotted 2px #e8e8e8;
}
.builder-component .formio-component-htmlelement [ref="html"]:empty:before {
  content: "HTML Content";
  color: #aaa;
}
.builder-component:not(:hover) .component-btn-group {
  display: none;
}
.builder-group-button {
  background-color: transparent;
  white-space: normal;
  text-align: left;
}
.form-builder-group-header {
  padding: 0;
}
.component-btn-group .component-settings-button {
  float: right;
  z-index: 1001;
  margin: 4px 4px 0 0;
  z-index: 1001;
  -webkit-box-shadow: 0 0 10px 1px rgba(48, 113, 169, 0.6);
  -moz-box-shadow: 0 0 10px 1px rgba(48, 113, 169, 0.6);
  box-shadow: 0 0 10px 1px rgba(48, 113, 169, 0.6);
}
.formbuilder .formio-component-content,
.formbuilder .formio-component-datasource,
.formbuilder .formio-component-form,
.formbuilder .formio-component-hidden {
  border: 2px dashed #ddd;
}
.formbuilder .formio-component-datasource,
.formbuilder .formio-component-form,
.formbuilder .formio-component-hidden {
  min-height: 3em;
  text-align: center;
  color: #aaa;
  padding-top: 0.5em;
}
.btn-group-xxs > .btn,
.btn-xxs,
.component-btn-group .component-settings-button {
  padding: 2px 2px;
  font-size: 10px;
  line-height: 1.2em;
  border-radius: 0;
  width: 18px;
  height: 18px;
}
.formcomponents .formcomponent {
  text-align: left;
  padding: 5px 5px 5px 8px;
  margin-top: 0.2rem;
  font-size: 0.8em;
  line-height: 1.2;
  border-radius: 0.3em;
}
.form-builder-panel .panel-body {
  padding: 5px;
}
.formio-component-tabs .ui.tabular.menu .item {
  padding: 0.8em;
}
.formio-pdf-builder {
  position: relative;
}
.formio-drop-zone {
  display: none;
  position: absolute;
  z-index: 10;
  background-color: #0d87e9;
  opacity: 0.1;
}
.formio-drop-zone.enabled {
  display: inherit;
}
.component-settings .formio-dialog-content {
  max-height: 100%;
}
.component-settings .formio-dialog-content .ck-editor__editable ol {
  padding-inline-start: 40px;
}
.component-btn-group .btn.component-settings-button-paste {
  display: none;
}
.builder-paste-mode .component-settings-button-paste {
  display: inherit !important;
}
.wizard-page-label {
  cursor: pointer;
  border-radius: 0;
}
.panel-body .drag-and-drop-alert {
  margin-bottom: 0;
}
.builder-sidebar_scroll {
  position: sticky;
  top: 15px;
  max-height: 100vh;
  overflow-y: auto;
}
.builder-sidebar_search {
  margin-bottom: 10px;
  appearance: auto;
}
.formio-wizard-builder-component-title {
  color: #6c757d;
  text-align: center;
  padding: 0.5rem;
}
.formio-wizard-position {
  position: relative;
}
.gu-mirror {
  list-style-type: none;
}
.formio-settings-help {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  margin-top: 10px;
}
.help-block {
  margin: 0;
}
.builder-sidebar .btn {
  white-space: normal;
}
.component-settings {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.component-edit-container {
  height: auto;
  overflow: hidden;
}
.component-edit-content {
  height: calc(100% - 4em);
}
.component-edit-tabs.col-sm-6 {
  min-height: 87vh;
  height: 100%;
}
.component-edit-tabs.col-sm-12 {
  height: calc(100% - 4em);
  overflow-y: auto;
}
.component-edit-tabs.col-sm-12 .editForm {
  height: calc(100% - 4em);
  overflow-y: auto;
}
.progress.pdf-progress {
  height: 2rem;
}
.progress.pdf-progress .progress-bar {
  font-size: 1rem;
  line-height: 2rem;
}
.builder-sidebar.disabled .formcomponent {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}
.builder-component-selected {
  border: 2px dashed #919191;
  outline: 0 !important;
}
</style>
